// React
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createHashRouter, RouterProvider } from "react-router-dom";
// Material
import { Container } from "@mui/material";
// Components
import Footer from "./components/Footer";
// Pages
import PageNotFound from "./pages/PageNotFound";
import Objects from "./pages/Objects";
import Authorization from "./pages/Authorization";
import CreateProfile from "./pages/CreateProfile";
import AddObject from "./pages/AddObject";
import AddCamera from "./pages/AddCamera";
import QRScanner from "./pages/QRScanner";
import CamerasManualAdding from "./pages/CamerasManualAdding";
import ObjectsManualAdding from "./pages/ObjectsManualAdding";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import OTP from "./pages/OTP";
import EditMail from "./pages/EditMail";
import EditPhone from "./pages/EditPhone";
import EditObject from "./pages/EditObject";
import EditCamera from "./pages/EditCamera";
import EditPassword from "./pages/EditPassword";
import Profile from "./pages/Profile";
import DeleteProfile from "./pages/DeleteProfile";
import Notifications from "./pages/Notifications";
import Settings from "./pages/Settings";
import Cameras from "./pages/Cameras";
import WatchStream from "./pages/WatchStream";
import WatchClip from "./pages/WatchClip";
import Activate from "./pages/Activate";
import Recover from "./pages/Recover";
import RecoverPasswordForm from "./pages/RecoverPasswordForm";
import PasswordChangedPage from "./pages/PasswordChangedPage";
// Local storage
import { setLanguage, getLanguage, setNotificationsStatus, getNotificationsStatus } from "./redux/local_storage";
// Other
import { routes } from "./constants/routes";
import { pingInterval } from "./constants/helpers";
import { prolongToken } from "./redux/auth/auth_operations";
import { isLogin, isLoadingAuth } from "./redux/auth/auth_selectors";
import { isLoadingStreams } from "./redux/streams/streams_selectors";
import { isLoadingObjects } from "./redux/objects/objects_selectors";
import { isLoadingVendors } from "./redux/vendors/vendors_selectors";
import { isLoadingService } from "./redux/service/service_selectors";

const App = React.memo(() => {
    const dispatch = useDispatch();
    const [isAppOnline, setIsAppOnline] = useState(navigator.onLine);
    const _isLogin = useSelector(isLogin);
    const _isLoadingAuth = useSelector(isLoadingAuth);
    const _isLoadingStreams = useSelector(isLoadingStreams);
    const _isLoadingObjects = useSelector(isLoadingObjects);
    const _isLoadingVendors = useSelector(isLoadingVendors);
    const _isLoadingService = useSelector(isLoadingService);
    const isLoading = _isLoadingAuth || _isLoadingStreams || _isLoadingObjects || _isLoadingVendors || _isLoadingService;
    const _token = localStorage.getItem("token");
    const _email = localStorage.getItem("email");

    const {
        home,
        objects,
        authorization,
        createProfile,
        addObject,
        addCamera,
        qrScanner,
        camerasManualAdding,
        objectsManualAdding,
        login,
        forgotPassword,
        otp,
        editMail,
        editPhone,
        editObject,
        editCamera,
        editPassword,
        profile,
        deleteProfile,
        notifications,
        settings,
        cameras,
        watchStream,
        watchClip,
        activate,
        recover,
        recover_pass_form,
        password_changed_page,
    } = routes;

    useEffect(() => {
        if (getLanguage()) {
            setLanguage(getLanguage());
        } else {
            setLanguage("ukr");
        }

        if (getNotificationsStatus()) {
            setNotificationsStatus(getNotificationsStatus());
        } else {
            setNotificationsStatus(false);
        }
    }, []);

    useEffect(() => {
        let networkConnectionChecker;
        networkConnectionChecker = setInterval(() => {
            if (isAppOnline !== navigator.onLine) {
                setIsAppOnline(navigator.onLine);
            }
        }, 500);
        return () => clearInterval(networkConnectionChecker);
    }, [isAppOnline]);

    useEffect(() => {
        let timer = null;
        if (_token) {
            dispatch(prolongToken({ _token }));
            timer = setInterval(() => dispatch(prolongToken({ _token })), pingInterval);
        }
        return () => clearInterval(timer);
    }, [_token, _email]);

    const router = createHashRouter([
        {
            element: <Footer isAppOnline={isAppOnline} />,
            children: [
                {
                    errorElement: <PageNotFound />,
                },
                {
                    path: home,
                    element: _isLogin ? <Objects isLoading={isLoading} /> : <Authorization isLoading={isLoading} />,
                },
                {
                    path: objects,
                    element: <Objects isLoading={isLoading} />,
                },
                {
                    path: authorization,
                    element: <Authorization isLoading={isLoading} />,
                },
                {
                    path: createProfile,
                    element: <CreateProfile isLoading={isLoading} />,
                },
                {
                    path: addObject,
                    element: <AddObject isLoading={isLoading} />,
                },
                {
                    path: addCamera,
                    element: <AddCamera isLoading={isLoading} />,
                },
                {
                    path: qrScanner,
                    element: <QRScanner isLoading={isLoading} />,
                },
                {
                    path: camerasManualAdding,
                    element: <CamerasManualAdding isLoading={isLoading} />,
                },
                {
                    path: objectsManualAdding,
                    element: <ObjectsManualAdding isLoading={isLoading} />,
                },
                {
                    path: login,
                    element: <Login isLoading={isLoading} />,
                },
                {
                    path: forgotPassword,
                    element: <ForgotPassword isLoading={isLoading} />,
                },
                {
                    path: otp,
                    element: <OTP isLoading={isLoading} />,
                },
                {
                    path: editMail,
                    element: <EditMail isLoading={isLoading} />,
                },
                {
                    path: editPhone,
                    element: <EditPhone isLoading={isLoading} />,
                },
                {
                    path: editObject,
                    element: <EditObject isLoading={isLoading} />,
                },
                {
                    path: editCamera,
                    element: <EditCamera isLoading={isLoading} />,
                },
                {
                    path: editPassword,
                    element: <EditPassword isLoading={isLoading} />,
                },
                {
                    path: profile,
                    element: <Profile isLoading={isLoading} />,
                },
                {
                    path: deleteProfile,
                    element: <DeleteProfile isLoading={isLoading} />,
                },
                {
                    path: notifications,
                    element: <Notifications isLoading={isLoading} />,
                },
                {
                    path: settings,
                    element: <Settings isLoading={isLoading} />,
                },
                {
                    path: cameras,
                    element: <Cameras isLoading={isLoading} />,
                },
                {
                    path: watchStream,
                    element: <WatchStream isLoading={isLoading} />,
                },
                {
                    path: watchClip,
                    element: <WatchClip isLoading={isLoading} />,
                },
                {
                    path: activate,
                    element: <Activate isLoading={isLoading} />,
                },
                {
                    path: recover,
                    element: <Recover isLoading={isLoading} />,
                },
                {
                    path: recover_pass_form,
                    element: <RecoverPasswordForm isLoading={isLoading} />,
                },
                {
                    path: password_changed_page,
                    element: <PasswordChangedPage isLoading={isLoading} />,
                },
            ],
        },
    ]);

    return (
        <Container className="container" maxWidth="sm">
            <RouterProvider router={router} fallbackElement="Loading..." />
        </Container>
    );
});

export default App;

import { createSlice } from "@reduxjs/toolkit";
import { getUserIP, testPort } from "./service_operations";

export const initialState = {
    isLoadingService: false,
    userIP: "",
    getUserIPstatus: null,
    testPortStatus: null,
};

const serviceSlice = createSlice({
    name: "service",
    initialState,
    reducers: {
        clearUserIP: (state) => {
            state.userIP = "";
        },
    },
    extraReducers: {
        // get user IP
        [getUserIP.pending]: (state) => {
            state.isLoadingService = false;
            state.getUserIPstatus = null;
            state.testPortStatus = null;
        },
        [getUserIP.fulfilled]: (state, { payload }) => {
            state.isLoadingService = false;
            if (payload) {
                state.userIP = payload.data.addr;
                state.getUserIPstatus = payload.status;
            }
        },
        [getUserIP.rejected]: (state, { payload }) => {
            state.isLoadingService = false;
            if (payload) {
                state.userIP = payload.data;
                state.getUserIPstatus = payload.status;
            }
        },
        // test port
        [testPort.pending]: (state) => {
            state.isLoadingService = false;
            state.getUserIPstatus = null;
            state.testPortStatus = null;
        },
        [testPort.fulfilled]: (state, { payload }) => {
            state.isLoadingService = false;
            if (payload) {
                state.testPortStatus = payload.status;
            }
        },
        [testPort.rejected]: (state, { payload }) => {
            state.isLoadingService = false;
            if (payload) {
                state.testPortStatus = payload.status;
            }
        },
    },
});

export const { clearUserIP } = serviceSlice.actions;

export const serviceReducer = serviceSlice.reducer;

// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// Components
import Header from "../components/Header";
import CustomButton from "../components/CustomButton";
import DropDown from "../components/DropDown";
import BasicModal from "../components/Modal";
import Loader from "../components/Loader";
// Material
import { Box, Card, CardActionArea, CardMedia, Typography } from "@mui/material";
// Img
import preview_icon from "../img/preview_object.svg";
// Other
import { user, isLogin } from "../redux/auth/auth_selectors";
import { enLang } from "../redux/global/global_selectors";
import { objectsList, deleteObjectStatus } from "../redux/objects/objects_selectors";
import { getObjectsList, deleteObject } from "../redux/objects/objects_operations";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const Objects = ({ isLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [objectID, setObjectID] = useState(null);
    const _enLang = useSelector(enLang);
    const _isLogin = useSelector(isLogin);
    const _objectsList = useSelector(objectsList);
    const _deleteObjectStatus = useSelector(deleteObjectStatus);
    const { token } = useSelector(user);

    const lang = _enLang ? translations.en : translations.ukr;

    const { addObject, cameras, home } = routes;
    const { object, locals_objects, delete_object, add_object_location } = lang;

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }

        dispatch(getObjectsList({ token, _isLogin }));
    }, [dispatch, _deleteObjectStatus]);

    const showObjectCamerasHandler = (id) => {
        navigate(cameras, { state: { id } });
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="objects">
                    <Header text={locals_objects} showBackArrow={false}></Header>
                    <Box className="objects_content">
                        <Box className="objects_card_holder">
                            {_objectsList &&
                                _objectsList.map(({ id, name }) => (
                                    <Card key={"object_" + id} className="objects_card">
                                        <CardActionArea>
                                            <Box className="objects_card_title_wrapper">
                                                <Typography className="objects_card_title">{object + ": " + name}</Typography>
                                                <DropDown
                                                    setOpenModal={setOpenModal}
                                                    setID={setObjectID}
                                                    id={id}
                                                    objectName={name}
                                                    token={token}
                                                />
                                            </Box>
                                            <CardMedia
                                                component="img"
                                                image={preview_icon}
                                                onClick={() => showObjectCamerasHandler(id)}
                                                alt={name}
                                            />
                                        </CardActionArea>
                                        <BasicModal
                                            openModal={openModal}
                                            setOpenModal={setOpenModal}
                                            text={delete_object}
                                            id={objectID}
                                            deleteObject={deleteObject}
                                        />
                                    </Card>
                                ))}
                        </Box>
                        <Box className="global_button_wrapper">
                            <Link to={addObject}>
                                <CustomButton style="dark" variant="contained" text={add_object_location} />
                            </Link>
                        </Box>
                    </Box>
                    <Box></Box>
                </Box>
            )}
        </>
    );
};

Objects.propTypes = {
    isLoading: PropTypes.bool,
};

export default Objects;

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import VanillaToasts from "../../components/toasts/vanillatoasts";
import { translations } from "../../constants/translation";
import { /* setSuccessNotification */ setWarningNotification, setErrorNotification, getLanguage } from "../local_storage";

// get streams list
export const getStreamsList = createAsyncThunk("streams/getStreamsList", async ({ token, _isLogin, _showNotifications }) => {
    const { error500, streams_has_been_loaded, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.get("/api/v1/streams", { headers: { "X-Auth-Key": token } });

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: streams_has_been_loaded,
                    type: "success",
                    timeout: 5000,
                });
            }

            if (_isLogin) {
                // setSuccessNotification(status, streams_has_been_loaded);
            }
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });

            if (_isLogin) {
                setWarningNotification(status);
            }
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });

        if (_isLogin) {
            setErrorNotification(e);
        }

        return { status: e.response.status };
    }
});

// get object streams list
export const getObjectStreamsList = createAsyncThunk("streams/getStreamsList", async ({ token, id, _isLogin, _showNotifications }) => {
    const { error500, streams_has_been_loaded, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.get("/api/v1/streams?group_id=" + id, { headers: { "X-Auth-Key": token } });

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: streams_has_been_loaded,
                    type: "success",
                    timeout: 5000,
                });
            }

            if (_isLogin) {
                // setSuccessNotification(status, streams_has_been_loaded);
            }
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });

            if (_isLogin) {
                setWarningNotification(status);
            }
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });

        if (_isLogin) {
            setErrorNotification(e);
        }

        return { status: e.response.status };
    }
});

// create stream
export const createStream = createAsyncThunk(
    "streams/createStream",
    async ({ source, source_internal, slot_id, codec, group_id, camera_model_id, token, _isLogin, _showNotifications }) => {
        const { error500, stream_has_been_created, this_service_is_unavailable, success, warning, error, something_went_wrong } =
            getLanguage() === "en" ? translations.en : translations.ukr;
        const params = { source, source_internal, slot_id, codec, group_id, camera_model_id };
        try {
            const { data, status } = await axios.post("/api/v1/streams", params, { headers: { "X-Auth-Key": token } });

            if (status === 200) {
                if (_showNotifications) {
                    VanillaToasts.create({
                        title: success,
                        text: stream_has_been_created,
                        type: "success",
                        timeout: 5000,
                    });
                }
                if (_isLogin) {
                    // setSuccessNotification(status, stream_has_been_created);
                }
            } else {
                VanillaToasts.create({
                    title: warning,
                    text: something_went_wrong + "Code: " + status,
                    type: "warning",
                    timeout: 5000,
                });

                if (_isLogin) {
                    setWarningNotification(status);
                }
            }
            return { data, status };
        } catch (e) {
            console.log({ message: e.response.data, status: e.response.status });
            if (
                (e.response.status === 423 && e.response.data === "stream slot is not free") ||
                (e.response.status === 422 &&
                    e.response.data.includes("Failed to deserialize the JSON body into the target type: slot_id: invalid type: null"))
            ) {
                VanillaToasts.create({
                    title: warning,
                    text: this_service_is_unavailable,
                    type: "warning",
                    timeout: 10000,
                });
            } else {
                VanillaToasts.create({
                    title: error,
                    text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
                    type: "error",
                    timeout: 5000,
                });
            }

            if (_isLogin) {
                setErrorNotification(e);
            }

            return { status: e.response.status, message: e.response.data };
        }
    }
);

// delete stream
export const deleteStream = createAsyncThunk("streams/deleteStream", async ({ id, token, _isLogin, _showNotifications }) => {
    const { error500, stream_has_been_deleted, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.delete("/api/v1/streams/" + id, { headers: { "X-Auth-Key": token } });

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: stream_has_been_deleted,
                    type: "success",
                    timeout: 5000,
                });
            }

            if (_isLogin) {
                // setSuccessNotification(status, stream_has_been_deleted);
            }
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });

            if (_isLogin) {
                setWarningNotification(status);
            }
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });

        if (_isLogin) {
            setErrorNotification(e);
        }

        return { status: e.response.status };
    }
});

// edit stream
export const editStream = createAsyncThunk(
    "streams/editStream",
    async ({ source, source_internal, codec, token, id, camera_model_id, _isLogin, _showNotifications }) => {
        const { error500, stream_has_been_edited, success, warning, error, something_went_wrong } =
            getLanguage() === "en" ? translations.en : translations.ukr;
        const params = { source, source_internal, codec, camera_model_id };
        try {
            const { data, status } = await axios.patch("/api/v1/streams/" + id, params, { headers: { "X-Auth-Key": token } });

            if (status === 200) {
                if (_showNotifications) {
                    VanillaToasts.create({
                        title: success,
                        text: stream_has_been_edited,
                        type: "success",
                        timeout: 5000,
                    });
                }

                if (_isLogin) {
                    // setSuccessNotification(status, stream_has_been_edited);
                }
            } else {
                VanillaToasts.create({
                    title: warning,
                    text: something_went_wrong + "Code: " + status,
                    type: "warning",
                    timeout: 5000,
                });

                if (_isLogin) {
                    setWarningNotification(status);
                }
            }
            return { data, status };
        } catch (e) {
            console.log({ message: e.response.data, status: e.response.status });
            VanillaToasts.create({
                title: error,
                text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
                type: "error",
                timeout: 5000,
            });

            if (_isLogin) {
                setErrorNotification(e);
            }

            return { status: e.response.status };
        }
    }
);

// get stream url
export const getStreamURL = createAsyncThunk("streams/getStreamURL", async ({ token, id, _isLogin, _showNotifications }) => {
    const { error500, stream_url_has_been_created, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.get(`/api/v1/streams/${id}/hls`, { headers: { "X-Auth-Key": token } });

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: stream_url_has_been_created,
                    type: "success",
                    timeout: 5000,
                });
            }

            if (_isLogin) {
                // setSuccessNotification(status, stream_url_has_been_created);
            }
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });

            if (_isLogin) {
                setWarningNotification(status);
            }
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });

        if (_isLogin) {
            setErrorNotification(e);
        }

        return { status: e.response.status };
    }
});

// create clip
export const createClip = createAsyncThunk("streams/createClip", async ({ token, id, t_start, t_end, _isLogin, _showNotifications }) => {
    const { error500, clip_url_has_been_created, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    const timingStore = [];
    const urlStore = [];
    let startTime = t_start;

    for (let i = 0; i < (t_end - t_start) / 180; i++) {
        timingStore.push([startTime, startTime + 180]);
        startTime += 180;
    }

    for (let i = 0; i < timingStore.length; i++) {
        urlStore.push(`/api/v1/query.clip?id=${id}&t_start=${timingStore[i][0]}&t_end=${timingStore[i][1]}&k=${token}`);
    }

    const params = { id, t_start, t_end: t_start + 180, k: token };
    try {
        const { status } = await axios.get(`/api/v1/query.clip?id=${id}&t_start=${t_start}&t_end=${t_start + 180}&k=${token}`, params);

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: clip_url_has_been_created,
                    type: "success",
                    timeout: 5000,
                });
            }

            if (_isLogin) {
                // setSuccessNotification(status, clip_url_has_been_created);
            }
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });

            if (_isLogin) {
                setWarningNotification(status);
            }
        }
        return { status, url: urlStore };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });

        if (_isLogin) {
            setErrorNotification(e);
        }

        return { status: e.response.status };
    }
});

// get stream url
export const getStreamSlots = createAsyncThunk("streams/getStreamSlots", async ({ token, _isLogin, _showNotifications }) => {
    const { error500, stream_slots_has_been_received, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.get("/api/v1/stream_slots", { headers: { "X-Auth-Key": token } });

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: stream_slots_has_been_received,
                    type: "success",
                    timeout: 5000,
                });
            }

            if (_isLogin) {
                // setSuccessNotification(status, stream_slots_has_been_received);
            }
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });

            if (_isLogin) {
                setWarningNotification(status);
            }
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });

        if (_isLogin) {
            setErrorNotification(e);
        }

        return { status: e.response.status };
    }
});

// send email application
export const sendEmailApplication = createAsyncThunk(
    "streams/sendEmailApplication",
    async ({ token, _isLogin, _showNotifications, tariff_plan }) => {
        const { error500, email_has_been_sent, success, warning, error, something_went_wrong } =
            getLanguage() === "en" ? translations.en : translations.ukr;
        try {
            const { data, status } = await axios.post("/api/v1/streams/request", { tariff_plan }, { headers: { "X-Auth-Key": token } });

            if (status === 200) {
                if (_showNotifications) {
                    VanillaToasts.create({
                        title: success,
                        text: email_has_been_sent,
                        type: "success",
                        timeout: 5000,
                    });
                }

                if (_isLogin) {
                    // setSuccessNotification(status, stream_slots_has_been_received);
                }
            } else {
                VanillaToasts.create({
                    title: warning,
                    text: something_went_wrong + "Code: " + status,
                    type: "warning",
                    timeout: 5000,
                });

                if (_isLogin) {
                    setWarningNotification(status);
                }
            }
            return { data, status };
        } catch (e) {
            console.log({ message: e.response.data, status: e.response.status });
            VanillaToasts.create({
                title: error,
                text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
                type: "error",
                timeout: 5000,
            });

            if (_isLogin) {
                setErrorNotification(e);
            }

            return { status: e.response.status };
        }
    }
);

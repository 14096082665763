// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// Components
import Logo from "../components/Logo";
import Title from "../components/Title";
import CustomButton from "../components/CustomButton";
import Loader from "../components/Loader";
// Material
import { FormControl, Box } from "@mui/material";
// Other
import { enLang } from "../redux/global/global_selectors";
import { isLogin } from "../redux/auth/auth_selectors";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const Authorization = ({ isLoading }) => {
    const navigate = useNavigate();
    const _isLogin = useSelector(isLogin);
    const _enLang = useSelector(enLang);

    const lang = _enLang ? translations.en : translations.ukr;
    const { registration, _login } = lang;
    const { createProfile, login } = routes;

    useEffect(() => {
        if (_isLogin) {
            navigate(-1);
            return;
        }
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="authorization">
                    <Box className="authorization_logo_wrapper">
                        <Logo />
                        <Title tag="h1" text="NVISION" />
                    </Box>
                    <FormControl fullWidth>
                        <Box className="authorization_button_wrapper">
                            <Link to={login}>
                                <CustomButton style="dark" variant="contained" text={_login} />
                            </Link>
                            <Link to={createProfile}>
                                <CustomButton style="transparent" variant="text" text={registration} />
                            </Link>
                        </Box>
                    </FormControl>
                    <Box />
                </Box>
            )}
        </>
    );
};

Authorization.propTypes = {
    isLoading: PropTypes.bool,
};

export default Authorization;

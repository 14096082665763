// React
import React from "react";
import { useSelector } from "react-redux";
// Material
import { Box } from "@mui/material";
// Other
import { enLang } from "../redux/global/global_selectors";
import { translations } from "../constants/translation";

const PageNotFound = () => {
    const _enLang = useSelector(enLang);
    const lang = _enLang ? translations.en : translations.ukr;

    const { page_not_found, redirect_to_homepage } = lang;

    return (
        <Box className="page_not_found">
            <Box className="page_not_found_text">{page_not_found}</Box>
            <span className="page_not_found_helper_text">{redirect_to_homepage}</span>
        </Box>
    );
};

export default PageNotFound;

// React
import React from "react";
import PropTypes from "prop-types";
import { useNavigate, Link } from "react-router-dom";
// Img
import back_arrow_icon from "../img/back_arrow.svg";
import settings_icon from "../img/settings.svg";
// Components
import Title from "./Title";
// Other
import { routes } from "../constants/routes";

const Header = ({ text, showBackArrow = true, showSettingsIcon = false }) => {
    const navigate = useNavigate();

    const { settings } = routes;

    const goBackHandler = () => {
        navigate(-1);
    };

    return (
        <div className="header_wrapper">
            {showBackArrow ? (
                <img className="back_arrow_icon" src={back_arrow_icon} onClick={goBackHandler} alt="back arrow" />
            ) : (
                <div className="helper_block"></div>
            )}
            <Title tag="h3" text={text} />
            {showSettingsIcon ? (
                <Link to={settings}>
                    <img className="settings_icon" src={settings_icon} alt="settings" />
                </Link>
            ) : (
                <div className="helper_block"></div>
            )}
        </div>
    );
};

Header.propTypes = {
    text: PropTypes.string,
    showBackArrow: PropTypes.bool,
    showSettingsIcon: PropTypes.bool,
};

export default Header;

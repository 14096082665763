// React
import React from "react";
import PropTypes from "prop-types";

const Title = ({ tag, text }) => {
    const contentRender = () => {
        let content = "";

        switch (tag) {
            case "h1":
                content = <h1 className="title">{text}</h1>;
                break;
            case "h2":
                content = <h2 className="title">{text}</h2>;
                break;
            case "h3":
                content = <h3 className="title">{text}</h3>;
                break;
            case "h4":
                content = <h4 className="title">{text}</h4>;
                break;
            default:
        }
        return content;
    };
    return contentRender();
};

Title.propTypes = {
    tag: PropTypes.string,
    text: PropTypes.string,
};

export default Title;

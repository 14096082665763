export const emailFormat = /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const passwordRules = ["minLength", "number", "letter"];
export const objectNameRules = ["minLength", "maxLength"];
export const pingInterval = 300000;
export const updateStreamTokenInterval = 270000;
export const tariffPlans = [
    {
        retention: 1,
        tariffName: "Пробний",
    },
    {
        retention: 3,
        tariffName: "Мінімальний",
    },
    { retention: 7, tariffName: "Оптимальний" },
    { retention: 14, tariffName: "Преміум" },
];

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import VanillaToasts from "../../components/toasts/vanillatoasts";
import { translations } from "../../constants/translation";
import { /* setSuccessNotification */ setWarningNotification, setErrorNotification, removeToken, getLanguage } from "../local_storage";

// login user
export const logIn = createAsyncThunk("auth/logIn", async ({ email, password, _showNotifications }) => {
    const { error500, login_has_been_successful, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    const userCredentials = { email, password };
    try {
        const { data, status } = await axios.post("/api/v1/login", userCredentials);

        if (status === 200) {
            const { email, token, user_id } = data;

            localStorage.setItem("email", email);
            localStorage.setItem("token", token);
            localStorage.setItem("user_id", user_id);

            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: login_has_been_successful,
                    type: "success",
                    timeout: 5000,
                });
            }
            // setSuccessNotification(status, login_has_been_successful);
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        if (e.response.status === 500) {
            VanillaToasts.create({
                title: error,
                text: error500,
                type: "error",
                timeout: 5000,
            });
        }

        return { message: e.response.data, status: e.response.status };
    }
});

// logout user
export const logOut = createAsyncThunk("auth/logOut", async ({ token, _showNotifications }) => {
    const { error500, logout_has_been_successful, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.post("/api/v1/logout", {}, { headers: { "X-Auth-Key": token } });

        if (status === 200) {
            removeToken();
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: logout_has_been_successful,
                    type: "success",
                    timeout: 5000,
                });
            }

            // setSuccessNotification(status, logout_has_been_successful);
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });
        return { status: e.response.status };
    }
});

// register user
export const signUp = createAsyncThunk("auth/signUp", async ({ email, password, _showNotifications }) => {
    const { error500, signup_has_been_successful, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    const userCredentials = { email, password };
    try {
        const { data, status } = await axios.post("/api/v1/users", userCredentials);

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: signup_has_been_successful,
                    type: "success",
                    timeout: 5000,
                });
            }
            // setSuccessNotification(status, signup_has_been_successful);
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        if (e.response.status === 500) {
            VanillaToasts.create({
                title: error,
                text: error500,
                type: "error",
                timeout: 7000,
            });
        }
        return { status: e.response.status };
    }
});

// prolong token
export const prolongToken = createAsyncThunk("auth/prolongToken", async ({ _token, _showNotifications }) => {
    const { error500, token_prolongation_has_been_successful, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.get("/api/v1/ping", { headers: { "X-Auth-Key": _token } });

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: token_prolongation_has_been_successful,
                    type: "success",
                    timeout: 5000,
                });
            }
            // setSuccessNotification(status, token_prolongation_has_been_successful);
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });
            setWarningNotification(status);
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        if (e.response.data !== "invalid token") {
            VanillaToasts.create({
                title: error,
                text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
                type: "error",
                timeout: 5000,
            });
        }

        setErrorNotification(e);

        removeToken();

        location.reload();
        return { status: e.response.status };
    }
});

// activate user
export const activateUser = createAsyncThunk("auth/activateUser", async ({ token, _showNotifications }) => {
    const { error500, user_activation_has_been_successful, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.post("/api/v1/users/activate", { token }, { headers: { "Content-Type": "application/json" } });

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: user_activation_has_been_successful,
                    type: "success",
                    timeout: 5000,
                });
            }
            // setSuccessNotification(status, user_activation_has_been_successful);
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });
        return { status: e.response.status };
    }
});

// delete user
export const deleteUser = createAsyncThunk("auth/deleteUser", async ({ email, password, token, _showNotifications }) => {
    const { error500, user_has_been_deleted, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.delete(
            "/api/v1/users",
            { data: { email, password } },
            { headers: { "X-Auth-Key": token, "Content-Type": "application/json" } }
        );

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: user_has_been_deleted,
                    type: "success",
                    timeout: 5000,
                });
            }
            removeToken();
            // setSuccessNotification(status, user_has_been_deleted);
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });
            setWarningNotification(status);
        }
        console.log({ data, status });
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });
        setErrorNotification(e);
        return { status: e.response.status };
    }
});

// recover password request
export const recoverPasswordRequest = createAsyncThunk("auth/recoverPasswordRequest", async ({ email, _showNotifications }) => {
    const { error500, email_has_been_sent, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.post(
            "/api/v1/users/recover/request",
            { email },
            { headers: { "Content-Type": "application/json" } }
        );

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: email_has_been_sent,
                    type: "success",
                    timeout: 5000,
                });
            }
            // setSuccessNotification(status, email_has_been_sent);
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });
            setWarningNotification(status);
        }
        console.log({ data, status });
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        if (e.response.status === 500) {
            VanillaToasts.create({
                title: error,
                text: error500,
                type: "error",
                timeout: 5000,
            });
        }
        setErrorNotification(e);
        return { status: e.response.status };
    }
});

// recover password check token
export const recoverPasswordCheckToken = createAsyncThunk("auth/recoverPasswordCheckToken", async ({ token, _showNotifications }) => {
    const { error500, token_has_been_checked, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.post(
            "/api/v1/users/recover/check",
            { token },
            { headers: { "Content-Type": "application/json" } }
        );

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: token_has_been_checked,
                    type: "success",
                    timeout: 5000,
                });
            }
            // setSuccessNotification(status, token_has_been_checked);
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });
            setWarningNotification(status);
        }
        console.log({ data, status });
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });
        setErrorNotification(e);
        return { status: e.response.status };
    }
});

// recover password apply
export const recoverPasswordApply = createAsyncThunk("auth/recoverPasswordApply", async ({ token, password, _showNotifications }) => {
    const { error500, password_has_been_edited, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.post(
            "/api/v1/users/recover/apply",
            { token, password },
            { headers: { "Content-Type": "application/json" } }
        );

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: password_has_been_edited,
                    type: "success",
                    timeout: 5000,
                });
            }
            // setSuccessNotification(status, password_has_been_edited);
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });
            setWarningNotification(status);
        }
        console.log({ data, status });
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        if (e.response.status === 500) {
            VanillaToasts.create({
                title: error,
                text: error500,
                type: "error",
                timeout: 5000,
            });
        }

        setErrorNotification(e);
        return { status: e.response.status };
    }
});

// edit password
export const editPassword = createAsyncThunk("auth/editPassword", async ({ token, password, new_password, _showNotifications }) => {
    const { error500, password_has_been_edited, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.post(
            "/api/v1/users/password",
            { token, password, new_password },
            { headers: { "X-Auth-Key": token, "Content-Type": "application/json" } }
        );

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: password_has_been_edited,
                    type: "success",
                    timeout: 5000,
                });
            }
            // setSuccessNotification(status, password_has_been_edited);
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });

        return { status: e.response.status };
    }
});

// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// Components
import Logo from "../components/Logo";
import Title from "../components/Title";
import TextInput from "../components/TextInput";
import PasswordInput from "../components/PasswordInput";
import CustomButton from "../components/CustomButton";
import Loader from "../components/Loader";
// Material
import { FormControl, Box } from "@mui/material";
// Other
import { logIn } from "../redux/auth/auth_operations";
import { isLogin, logInMessage, logInStatus, logInUserEmail } from "../redux/auth/auth_selectors";
import { resetLoginStatus, resetLogInUserEmail, setLogInUserEmail } from "../redux/auth/auth_slice";
import { enLang } from "../redux/global/global_selectors";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const Login = ({ isLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _isLogin = useSelector(isLogin);
    const _logInMessage = useSelector(logInMessage);
    const _logInStatus = useSelector(logInStatus);
    const _logInUserEmail = useSelector(logInUserEmail);
    const _enLang = useSelector(enLang);
    const [email, setEmail] = useState(_logInUserEmail);
    const [password, setPassword] = useState("");
    const [invalidCred, setInvalidCred] = useState(false);
    const [userIsNotActive, setUserIsNotActive] = useState(false);

    const lang = _enLang ? translations.en : translations.ukr;

    const { _email, enter, forgot_password, invalid_credentials_error_text, user_is_not_active_error_text } = lang;
    const { forgotPassword, home } = routes;

    useEffect(() => {
        if (_logInStatus) {
            if (_logInStatus === 200) {
                setInvalidCred(false);
                dispatch(resetLogInUserEmail());
            } else if (_logInMessage === "invalid credentials") {
                setInvalidCred(true);
            } else if (_logInMessage === "user is not active") {
                setUserIsNotActive(true);
            }
        }

        if (_isLogin) {
            navigate(home);
        }

        return () => dispatch(resetLoginStatus());
    }, [_isLogin, _logInStatus]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(setLogInUserEmail({ email }));
        dispatch(logIn({ email, password }));
    };

    const handleChange = (e) => {
        if (invalidCred) {
            setInvalidCred(false);
        } else if (userIsNotActive) {
            setUserIsNotActive(false);
        }

        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case "email":
                setEmail(value);
                break;
            case "password":
                setPassword(value);
                break;
            default:
                return;
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="login">
                    <Box className="login_logo_wrapper">
                        <Logo />
                        <Title tag="h1" text="NVISION" />
                    </Box>
                    {invalidCred && <span className="helper_error_text">{invalid_credentials_error_text}</span>}
                    {userIsNotActive && (
                        <span className="helper_error_text">
                            {user_is_not_active_error_text.split("!")[0] + "!"} <br />
                            {user_is_not_active_error_text.split("!")[1]}
                        </span>
                    )}
                    <FormControl fullWidth>
                        <form onSubmit={handleSubmit}>
                            <Box className="login_input_wrapper">
                                <TextInput
                                    id="email"
                                    placeholder={_email}
                                    name="email"
                                    onChange={handleChange}
                                    value={email}
                                    error={invalidCred || userIsNotActive}
                                />
                                <PasswordInput name="password" onChange={handleChange} error={invalidCred || userIsNotActive} />
                            </Box>
                            <Box className="login_button_wrapper">
                                <CustomButton type="submit" style="dark" variant="contained" text={enter} />
                                <Link to={forgotPassword}>
                                    <CustomButton type="submit" style="transparent" variant="text" text={forgot_password} />
                                </Link>
                            </Box>
                        </form>
                    </FormControl>
                </Box>
            )}
        </>
    );
};

Login.propTypes = {
    isLoading: PropTypes.bool,
};

export default Login;

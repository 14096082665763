// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// Components
import Title from "../components/Title";
import CustomButton from "../components/CustomButton";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Logo from "../components/Logo";
// Libs
import { MuiOtpInput } from "mui-one-time-password-input";
// Material
import { Box } from "@mui/material";
// Other
import { isLogin } from "../redux/auth/auth_selectors";
import { enLang } from "../redux/global/global_selectors";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const OTP = ({ isLoading }) => {
    const [otp, setOtp] = useState("");
    const navigate = useNavigate();
    const _isLogin = useSelector(isLogin);
    const _enLang = useSelector(enLang);
    const lang = _enLang ? translations.en : translations.ukr;

    const { code, enter_the_code, send_code_again, confirm } = lang;
    const { home } = routes;

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }
    }, []);

    const handleChange = (newValue) => {
        setOtp(newValue);
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="otp">
                    <Header text={code} />
                    <Box className="otp_logo_wrapper">
                        <Logo />
                        <Title tag="h1" text="NVISION" />
                    </Box>
                    <Box className="otp_wrapper">
                        <Title tag="h4" text={enter_the_code} />
                        <MuiOtpInput
                            className="otp_input"
                            value={otp}
                            length={6}
                            onChange={handleChange}
                            TextFieldsProps={{ disabled: false, size: "small" }}
                        />
                        <span className="otp_helper_text">{send_code_again}</span>
                        <Box className="global_button_wrapper">
                            <CustomButton style="dark" variant="contained" text={confirm} />
                        </Box>
                    </Box>
                    <Box></Box>
                </Box>
            )}
        </>
    );
};

OTP.propTypes = {
    isLoading: PropTypes.bool,
};

export default OTP;

/* eslint-disable no-unused-vars */
// React
import React from "react";
import { useLocation, Link, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
// Img
import home_icon from "../img/home.svg";
import camera_icon from "../img/camera.svg";
import alarms_icon from "../img/alarms.svg";
import profile_icon from "../img/profile.svg";
import home_active_icon from "../img/home_active.svg";
import camera_active_icon from "../img/camera_active.svg";
import alarms_active_icon from "../img/alarms_active.svg";
import profile_active_icon from "../img/profile_active.svg";
// Components
import OfflineLayout from "./OfflineLayout";
// Material
import { Box, Badge } from "@mui/material";
// Other
import { routes } from "../constants/routes";
import { isLogin } from "../redux/auth/auth_selectors";

const Footer = ({ isAppOnline }) => {
    const { pathname } = useLocation();
    const _isLogin = useSelector(isLogin);
    const { home, cameras, notifications, profile } = routes;

    // const badgeContent = localStorage.length - 5;
    const badgeContent = 0;

    const homePage = pathname === home;
    const camerasPage = pathname === cameras;
    const notificationsPage = pathname === notifications;
    const profilePage = pathname === profile;

    return (
        <>
            {isAppOnline ? <Outlet /> : <OfflineLayout />}
            <Box className="footer_wrapper">
                <Link to={isAppOnline && home}>
                    <img className="footer_icon" src={homePage ? home_active_icon : home_icon} alt="home" />
                </Link>
                <Link to={isAppOnline && cameras}>
                    <img className="footer_icon" src={camerasPage ? camera_active_icon : camera_icon} alt="camera" />
                </Link>
                {/* <Link to={notifications}>
                    <Badge badgeContent={_isLogin ? badgeContent : 0} color="primary">
                        <img className="footer_icon" src={notificationsPage ? alarms_active_icon : alarms_icon} alt="alarms" />
                    </Badge>
                </Link> */}
                <Link to={isAppOnline && profile}>
                    <img className="footer_icon profile_icon" src={profilePage ? profile_active_icon : profile_icon} alt="profile" />
                </Link>
            </Box>
        </>
    );
};

Footer.propTypes = {
    isAppOnline: PropTypes.bool,
};

export default Footer;

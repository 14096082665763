// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// Components
import Logo from "../components/Logo";
import Title from "../components/Title";
import TextInput from "../components/TextInput";
import CustomButton from "../components/CustomButton";
import Header from "../components/Header";
import Loader from "../components/Loader";
// Material
import { FormControl, Box } from "@mui/material";
// Other
import { enLang } from "../redux/global/global_selectors";
import { recoverPasswordRequest } from "../redux/auth/auth_operations";
import { recoverPasswordRequestStatus, isLogin } from "../redux/auth/auth_selectors";
import { resetRecoverPasswordRequestStatus } from "../redux/auth/auth_slice";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";
import { emailFormat } from "../constants/helpers";

const ForgotPassword = ({ isLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _isLogin = useSelector(isLogin);
    const _enLang = useSelector(enLang);
    const statusCode = useSelector(recoverPasswordRequestStatus);
    const [email, setEmail] = useState("");
    const [disabledButton, setDisabledButton] = useState(true);
    const [nonExistentEmail, setNonExistentEmail] = useState(false);

    const lang = _enLang ? translations.en : translations.ukr;
    const { forgot_password, forgot_password_helper_text, send, no_such_email_exists } = lang;
    const { recover } = routes;

    useEffect(() => {
        if (_isLogin) {
            navigate(-1);
            return;
        }

        if (statusCode === 200) {
            navigate(recover);
        } else if (statusCode === 404) {
            setNonExistentEmail(true);
        }

        return () => dispatch(resetRecoverPasswordRequestStatus());
    }, [dispatch, statusCode]);

    const handleChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        if (nonExistentEmail) {
            setNonExistentEmail(false);
            dispatch(resetRecoverPasswordRequestStatus());
        }

        if (emailValue && emailValue.match(emailFormat)) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(recoverPasswordRequest({ email }));
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="forgot_password">
                    <Header text={forgot_password} />
                    <Box className="forgot_password_logo_wrapper">
                        <Logo />
                        <Title tag="h1" text="NVISION" />
                    </Box>
                    <span className="forgot_password_helper_text">{forgot_password_helper_text}</span>
                    <FormControl fullWidth>
                        {nonExistentEmail && <span className="forgot_password_error_text">{no_such_email_exists}</span>}
                        <form onSubmit={handleSubmit}>
                            <Box className="forgot_password_input_wrapper">
                                <TextInput id="forgot-password" onChange={handleChange} error={nonExistentEmail} />
                            </Box>
                            <Box className="forgot_password_button_wrapper">
                                <CustomButton type="submit" style="dark" variant="contained" text={send} disabled={disabledButton} />
                            </Box>
                        </form>
                    </FormControl>
                </Box>
            )}
        </>
    );
};

ForgotPassword.propTypes = {
    isLoading: PropTypes.bool,
};

export default ForgotPassword;

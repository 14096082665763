// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// Components
import Header from "../components/Header";
import Loader from "../components/Loader";
import CustomSwitch from "../components/CustomSwitch";
// Material
import { Box, List, ListItem, ListItemText } from "@mui/material";
//Img
import notification_icon from "../img/notification.svg";
// Other
import { showNotifications, enLang, ukrLang } from "../redux/global/global_selectors";
import { getAppVersion } from "../redux/global/global_operations";
import { appVersion } from "../redux/global/global_selectors";
import { isLogin } from "../redux/auth/auth_selectors";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const Settings = ({ isLoading }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const _isLogin = useSelector(isLogin);
    const _appVersion = useSelector(appVersion);
    const _showNotifications = useSelector(showNotifications);
    const _enLang = useSelector(enLang);
    const _ukrLang = useSelector(ukrLang);

    const lang = _enLang ? translations.en : translations.ukr;
    const { notifications, set_english_language, set_ukrainian_language, settings, version } = lang;

    const settingsList = [
        { data: "notifications", name: notifications, icon: notification_icon, enabled: _showNotifications },
        { data: "english", name: set_english_language, icon: notification_icon, enabled: _enLang },
        { data: "ukrainian", name: set_ukrainian_language, icon: notification_icon, enabled: _ukrLang },
    ];

    const { home } = routes;

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }
        dispatch(getAppVersion());
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="settings">
                    <Header text={settings} />
                    <List className="settings_list">
                        {settingsList.map(({ data, name, icon, enabled }, index) => (
                            <ListItem key={"setting_" + index} className="settings_list_item">
                                <img className="setting_icon" src={icon} alt="setting" />
                                <ListItemText className="settings_list_item_text">{name}</ListItemText>
                                <CustomSwitch enabled={enabled} data={data} />
                            </ListItem>
                        ))}
                        <div className="app_version">
                            {version} {_appVersion}
                        </div>
                    </List>
                    <Box></Box>
                </Box>
            )}
        </>
    );
};

Settings.propTypes = {
    isLoading: PropTypes.bool,
};

export default Settings;

// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// Components
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import PasswordInput from "../components/PasswordInput";
import Title from "../components/Title";
import CustomButton from "../components/CustomButton";
import Loader from "../components/Loader";
// Material
import { Box, FormControl } from "@mui/material";
// Img
import cautions_icon from "../img/cautions.svg";
// Other
import { enLang, showNotifications } from "../redux/global/global_selectors";
import { deleteUser } from "../redux/auth/auth_operations";
import { deleteUserStatus, user, isLogin } from "../redux/auth/auth_selectors";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const DeleteProfile = ({ isLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _isLogin = useSelector(isLogin);
    const _enLang = useSelector(enLang);
    const _showNotifications = useSelector(showNotifications);
    const _deleteUserStatus = useSelector(deleteUserStatus);
    const { token } = useSelector(user);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [disabledButton, setDisabledButton] = useState(true);

    const lang = _enLang ? translations.en : translations.ukr;
    const { delete_profile, cautions, delete_text_warning, _email, delete_all_cameras_warning } = lang;
    const { home } = routes;

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }

        if (email && password) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [email, password]);

    useEffect(() => {
        if (_deleteUserStatus === 200) {
            navigate(home);
            return;
        }
    }, [_deleteUserStatus]);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case "email":
                setEmail(value);
                break;
            case "password":
                setPassword(value);
                break;
            default:
                return;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(deleteUser({ email, password, token, _showNotifications }));
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="delete_profile">
                    <Header text={delete_profile} />
                    <Box className="delete_profile_content_wrapper">
                        <Box className="head_wrapper">
                            <img className="delete_profile_cautions_icon" src={cautions_icon} alt="cautions" />
                            <Title tag="h3" text={cautions} />
                            <span className="delete_profile_helper_text">{delete_text_warning}</span>
                        </Box>
                        <form className="delete_profile_form" onSubmit={handleSubmit}>
                            <span className="delete_profile_form_helper_text">{delete_all_cameras_warning}</span>
                            <FormControl fullWidth>
                                <TextInput id="email" name="email" placeholder={_email} onChange={handleChange} />
                                <PasswordInput id="password" name="password" onChange={handleChange} />
                            </FormControl>
                            <Box className="global_button_wrapper">
                                <CustomButton
                                    type="submit"
                                    style="dark"
                                    variant="contained"
                                    text={delete_profile}
                                    disabled={disabledButton}
                                />
                            </Box>
                        </form>
                    </Box>
                    <Box></Box>
                </Box>
            )}
        </>
    );
};

DeleteProfile.propTypes = {
    isLoading: PropTypes.bool,
};

export default DeleteProfile;

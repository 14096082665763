import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";

import "./styles/main.scss";
import "./components/toasts/vanillatoasts.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Suspense fallback="Loading ...">
        <Provider store={store}>
            <App />
        </Provider>
    </Suspense>
);

// React
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// Components
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import CustomButton from "../components/CustomButton";
import Loader from "../components/Loader";
// Material
import { FormControl, Box, Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Libs
import last from "lodash.last";
import sortBy from "lodash.sortby";
import forEach from "lodash.foreach";
// Other
import { getObjectsList } from "../redux/objects/objects_operations";
import { objectsList } from "../redux/objects/objects_selectors";
import { createStream, getStreamSlots, sendEmailApplication } from "../redux/streams/streams_operations";
import { getUserIP, testPort } from "../redux/service/service_operations";
import { clearUserIP } from "../redux/service/service_slice";
import { user, isLogin } from "../redux/auth/auth_selectors";
import { createStreamStatus, createStreamMessage, streamSlots } from "../redux/streams/streams_selectors";
import { showNotifications, enLang } from "../redux/global/global_selectors";
import { userIP } from "../redux/service/service_selectors";
import { getVendors, getCameraModels } from "../redux/vendors/vendors_operations";
import { vendorsList, cameraModelsList } from "../redux/vendors/vendors_selectors";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";
import { tariffPlans } from "../constants/helpers";

const CamerasManualAdding = ({ isLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useSelector(user);
    const _createStreamStatus = useSelector(createStreamStatus);
    const _createStreamMessage = useSelector(createStreamMessage);
    const _isLogin = useSelector(isLogin);
    const _showNotifications = useSelector(showNotifications);
    const _enLang = useSelector(enLang);
    const _objectsList = useSelector(objectsList);
    const _userIP = useSelector(userIP);
    const _vendorsList = useSelector(vendorsList);
    const _cameraModelsList = useSelector(cameraModelsList);
    const _streamSlots = sortBy(useSelector(streamSlots), "retention_hours");
    const [groupID, setGroupID] = useState(null);
    const [retention, setRetention] = useState(null);
    const [slotID, setSlotID] = useState(null);
    const [cameraCodec, setCameraCodec] = useState(null);
    const [producer, setProducer] = useState("");
    const [model, setModel] = useState("");
    const [modelID, setModelID] = useState(null);
    const [cameraLogin, setCameraLogin] = useState("");
    const [cameraPassword, setCameraPassword] = useState("");
    const [cameraIP, setCameraIP] = useState("");
    const [cameraPort, setCameraPort] = useState("");
    const [cameraSuffix, setCameraSuffix] = useState("");
    const [tariff, setTariff] = useState("");
    const [disabledTestPortButton, setDisabledTestPortButton] = useState(true);
    const [disabledButton, setDisabledButton] = useState(true);

    const lang = _enLang ? translations.en : translations.ukr;
    const {
        add_camera,
        _retention,
        group_id,
        save,
        name,
        order,
        _id,
        days,
        _producer,
        _model,
        _camera_login,
        _camera_password,
        _camera_ip,
        _camera_port,
        _camera_suffix,
        other,
        getIP,
        _testPort,
        _camera_codec,
        _default,
        ip_input_hint,
        port_input_hint,
        rtsp_url_input_hint,
    } = lang;

    const { cameras, home } = routes;

    const producerValues = [..._vendorsList, other];
    const codecValues = ["h265", _default];
    const modelValues = [..._cameraModelsList.map(({ name }) => name), other];

    const filterOptions = createFilterOptions({
        matchFrom: "any",
        limit: 200,
    });

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }

        dispatch(getVendors({ token, _isLogin }));
        dispatch(getStreamSlots({ token, _isLogin }));
        dispatch(getObjectsList({ token, _isLogin }));
    }, []);

    useEffect(() => {
        if (producer) {
            dispatch(getCameraModels({ token, _isLogin, vendor: producer }));
        }
    }, [producer]);

    useEffect(() => {
        const intermediateStatus =
            (_createStreamStatus === 423 && _createStreamMessage === "stream slot is not free") ||
            (_createStreamStatus === 422 &&
                _createStreamMessage.includes("Failed to deserialize the JSON body into the target type: slot_id: invalid type: null"));

        if (intermediateStatus) {
            dispatch(sendEmailApplication({ token, _isLogin, _showNotifications, tariff_plan: tariff }));
        }

        if (_createStreamStatus === 200 || intermediateStatus) {
            navigate(cameras, { state: retention });
            return;
        }
        return () => dispatch(clearUserIP());
    }, [_createStreamStatus, _createStreamMessage]);

    useEffect(() => {
        if (cameraIP && cameraPort) {
            setDisabledTestPortButton(false);
        } else {
            setDisabledTestPortButton(true);
        }
    }, [cameraIP, cameraPort]);

    useEffect(() => {
        if (
            producer &&
            model &&
            cameraLogin &&
            cameraPassword &&
            cameraIP &&
            cameraPort &&
            cameraSuffix &&
            retention &&
            groupID &&
            (cameraCodec || cameraCodec === null)
        ) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [producer, model, cameraLogin, cameraPassword, cameraIP, cameraPort, cameraSuffix, retention, groupID, cameraCodec]);

    useEffect(() => {
        setCameraIP(_userIP);
    }, [_userIP]);

    const getRetentionValues = useCallback(() => {
        const retentionValues = tariffPlans.map((plan) => {
            return days + ": " + plan.retention + " - " + order;
        });

        retentionValues.forEach((value, valueIdx) => {
            _streamSlots.forEach((slot) => {
                if (value.match(/\d+/)[0] * 24 === slot.retention_hours) {
                    if (slot.stream_id) {
                        retentionValues[valueIdx] = days + ": " + slot.retention_hours / 24 + " - " + order;
                    } else {
                        retentionValues[valueIdx] = days + ": " + slot.retention_hours / 24;
                    }
                }
            });
        });

        return retentionValues;
    }, [_streamSlots]);

    const getUserIpHandler = () => {
        dispatch(getUserIP({ token, _isLogin, _showNotifications }));
        setCameraIP(_userIP);
    };

    const testPortHandler = () => {
        dispatch(testPort({ addr: cameraIP, port: Number(cameraPort), token, _isLogin }));
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const id = e.target.id;
        const content = e.target.innerText;

        if (id.includes("group_id")) {
            setGroupID(last(content.split(":")));
        } else if (id.includes("retention")) {
            setRetention(content.split(" ")[1]);
            for (let i = 0; i < _streamSlots.length; i++) {
                if (Number(_streamSlots[i].retention_hours) === Number(content.match(/\d+/)[0] * 24)) {
                    setSlotID(_streamSlots[i].id);
                    break;
                } else {
                    setSlotID(null);
                }
            }
            forEach(tariffPlans, (tariff) => {
                if (tariff.retention === Number(content.split(" ")[1])) {
                    setTariff(tariff.tariffName);
                }
            });
        } else if (id.includes("codec")) {
            if (content === _default) {
                setCameraCodec(null);
            } else {
                setCameraCodec(content);
            }
        } else if (id.includes("producer")) {
            setProducer(content);
            setModel("");
            setCameraSuffix("");
            if (cameraSuffix && content === other) setCameraSuffix("");
        } else if (id.includes("model")) {
            setModel(content);
            if (cameraSuffix && content === other) setCameraSuffix("");
            _cameraModelsList.forEach(({ name, source, id }) => {
                if (content === name) {
                    setCameraSuffix(source);
                    setModelID(id);
                }
            });
        } else {
            switch (name) {
                case "camera_login":
                    setCameraLogin(value);
                    break;
                case "camera_password":
                    setCameraPassword(value);
                    break;
                case "camera_ip":
                    setCameraIP(value);
                    break;
                case "camera_port":
                    setCameraPort(value);
                    break;
                case "camera_suffix":
                    setCameraSuffix(value);
                    break;
                default:
                    return;
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(
            createStream({
                source: `rtsp://${cameraLogin}:${cameraPassword}@${cameraIP}:${cameraPort}/${cameraSuffix}`,
                source_internal: "",
                slot_id: slotID,
                codec: cameraCodec,
                group_id: Number(groupID),
                camera_model_id: Number(modelID),
                token,
                _isLogin,
                _showNotifications,
            })
        );
    };

    const setOptions = () => {
        const options = _objectsList.map((object) => {
            return `${name}: ${object.name}. ${_id}: ${object.id}`;
        });

        return options;
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="cameras_manual_adding">
                    <Header text={add_camera} />
                    <Box className="cameras_manual_adding_form_wrapper">
                        <FormControl fullWidth>
                            <form onSubmit={handleSubmit}>
                                <Box className="cameras_manual_adding_input_wrapper">
                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        disableClearable
                                        id="combo-box-demo producer"
                                        className="cameras_manual_adding_autocomplete"
                                        options={producerValues}
                                        name="producer"
                                        renderInput={(params) => <TextField {...params} label={_producer} />}
                                        onChange={handleChange}
                                    />
                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        disableClearable
                                        id="combo-box-demo model"
                                        className="cameras_manual_adding_autocomplete"
                                        filterOptions={filterOptions}
                                        options={modelValues}
                                        name="model"
                                        renderInput={(params) => <TextField {...params} label={_model} />}
                                        value={model || null}
                                        onChange={handleChange}
                                    />
                                    <TextInput id="camera_login" label={_camera_login} name="camera_login" onChange={handleChange} />
                                    <TextInput
                                        id="camera_password"
                                        label={_camera_password}
                                        name="camera_password"
                                        onChange={handleChange}
                                    />
                                    <Box className="camera_ip_wrapper">
                                        <Box className="camera_ip_wrapper_helper">
                                            <TextInput
                                                className="text_input"
                                                id="camera_ip"
                                                label={_camera_ip}
                                                name="camera_ip"
                                                onChange={handleChange}
                                                value={cameraIP}
                                                rightZeroBorder
                                                hint
                                            />
                                            <LoadingButton
                                                className="get_user_ip_button"
                                                loading={false}
                                                variant="outlined"
                                                onClick={getUserIpHandler}
                                            >
                                                {getIP}
                                            </LoadingButton>
                                        </Box>
                                        <span className="camera_ip_wrapper_hint">{ip_input_hint}</span>
                                    </Box>
                                    <Box className="camera_port_wrapper">
                                        <Box className="camera_port_wrapper_helper">
                                            <TextInput
                                                id="camera_port"
                                                label={_camera_port}
                                                name="camera_port"
                                                onChange={handleChange}
                                                rightZeroBorder
                                                hint
                                            />
                                            <LoadingButton
                                                className={"test_port_button " + (disabledTestPortButton ? "disabled" : "")}
                                                loading={false}
                                                variant="outlined"
                                                onClick={testPortHandler}
                                                disabled={disabledTestPortButton}
                                            >
                                                {_testPort}
                                            </LoadingButton>
                                        </Box>
                                        <span className="camera_port_wrapper_hint">{port_input_hint}</span>
                                    </Box>
                                    <Box className="camera_suffix_wrapper">
                                        <TextInput
                                            id="camera_suffix"
                                            label={_camera_suffix}
                                            name="camera_suffix"
                                            onChange={handleChange}
                                            value={cameraSuffix}
                                            hint
                                        />
                                        <span className="camera_suffix_wrapper_hint">{rtsp_url_input_hint}</span>
                                    </Box>
                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        disableClearable
                                        id="combo-box-demo codec"
                                        className="cameras_manual_adding_autocomplete"
                                        options={codecValues}
                                        name="codec"
                                        renderInput={(params) => <TextField {...params} label={_camera_codec} />}
                                        onChange={handleChange}
                                    />
                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        disableClearable
                                        id="combo-box-demo retention"
                                        className="cameras_manual_adding_autocomplete"
                                        options={getRetentionValues()}
                                        name="retention"
                                        renderInput={(params) => <TextField {...params} label={_retention} />}
                                        onChange={handleChange}
                                    />
                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        disableClearable
                                        id="combo-box-demo group_id"
                                        className="cameras_manual_adding_autocomplete"
                                        options={setOptions()}
                                        name="group_id"
                                        renderInput={(params) => <TextField {...params} label={group_id} />}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box className="global_button_wrapper">
                                    <CustomButton type="submit" style="dark" variant="contained" text={save} disabled={disabledButton} />
                                </Box>
                            </form>
                        </FormControl>
                    </Box>
                    <Box></Box>
                </Box>
            )}
        </>
    );
};

CamerasManualAdding.propTypes = {
    isLoading: PropTypes.bool,
};

export default CamerasManualAdding;

// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// Components
import Header from "../components/Header";
import Loader from "../components/Loader";
import TextInput from "../components/TextInput";
import CustomButton from "../components/CustomButton";
// Material
import { FormControl, Box } from "@mui/material";
// Libs
import PasswordChecklist from "react-password-checklist";
// Other
import { createObject } from "../redux/objects/objects_operations";
import { user, isLogin } from "../redux/auth/auth_selectors";
import { createObjectsStatus } from "../redux/objects/objects_selectors";
import { showNotifications, enLang } from "../redux/global/global_selectors";
import { objectNameRules } from "../constants/helpers";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const ObjectsManualAdding = ({ isLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useSelector(user);
    const statusCode = useSelector(createObjectsStatus);
    const _isLogin = useSelector(isLogin);
    const _showNotifications = useSelector(showNotifications);
    const _enLang = useSelector(enLang);
    const [name, setName] = useState("");
    const [disabledButton, setDisabledButton] = useState(true);

    const lang = _enLang ? translations.en : translations.ukr;

    const { home } = routes;

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }

        if (statusCode === 200) {
            navigate(home);
            return;
        }
    }, [statusCode]);

    useEffect(() => {
        if (name && name.length >= 2 && name.length <= 30) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [name]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(createObject({ token, _isLogin, name, _showNotifications }));
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case "name":
                setName(value);
                break;
            default:
                return;
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="objects_manual_adding">
                    <Header text={lang.add_object_location} />
                    <Box className="objects_manual_adding_form_wrapper">
                        <FormControl fullWidth>
                            <form onSubmit={handleSubmit}>
                                <Box className="objects_manual_adding_input_wrapper">
                                    <TextInput id="name" placeholder={lang.name} name="name" onChange={handleChange} />
                                    <PasswordChecklist
                                        rules={objectNameRules}
                                        minLength={2}
                                        maxLength={30}
                                        value={name}
                                        style={{ fontSize: "14px", marginBottom: "8px" }}
                                        iconSize={14}
                                        messages={{
                                            minLength: lang.object_name_contain[0],
                                            maxLength: lang.object_name_contain[1],
                                        }}
                                    />
                                </Box>
                                <Box className="objects_manual_adding_button_wrapper">
                                    <CustomButton
                                        type="submit"
                                        style="dark"
                                        variant="contained"
                                        text={lang.save}
                                        disabled={disabledButton}
                                    />
                                </Box>
                            </form>
                        </FormControl>
                    </Box>
                    <Box></Box>
                </Box>
            )}
        </>
    );
};

ObjectsManualAdding.propTypes = {
    isLoading: PropTypes.bool,
};

export default ObjectsManualAdding;

// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// Components
import Header from "../components/Header";
import Loader from "../components/Loader";
import TextInput from "../components/TextInput";
import CustomButton from "../components/CustomButton";
// Material
import { FormControl, Box } from "@mui/material";
// Libs
import PasswordChecklist from "react-password-checklist";
// Other
import { editObject } from "../redux/objects/objects_operations";
import { isLogin } from "../redux/auth/auth_selectors";
import { showNotifications, enLang } from "../redux/global/global_selectors";
import { editObjectStatus } from "../redux/objects/objects_selectors";
import { objectNameRules } from "../constants/helpers";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const EditObject = ({ isLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const _isLogin = useSelector(isLogin);
    const _showNotifications = useSelector(showNotifications);
    const _enLang = useSelector(enLang);
    const statusCode = useSelector(editObjectStatus);

    const { id, objectName, token } = location.state || {};

    const [name, setName] = useState(objectName);
    const [disabledButton, setDisabledButton] = useState(false);

    const lang = _enLang ? translations.en : translations.ukr;

    const { home } = routes;

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }

        if (statusCode === 200) {
            navigate(home);
            return;
        }
    }, [statusCode]);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case "name":
                if (value && value.length >= 2 && value.length <= 30) {
                    setDisabledButton(false);
                } else {
                    setDisabledButton(true);
                }
                setName(value);
                break;
            default:
                return;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(editObject({ token, _isLogin, name, id, _showNotifications }));
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="edit_object">
                    <Header text={lang.edit_object} />
                    <FormControl fullWidth className="edit_camera_object">
                        <form onSubmit={handleSubmit}>
                            <TextInput id="name" name="name" defaultValue={objectName} label={lang.name} onChange={handleChange} />
                            <PasswordChecklist
                                rules={objectNameRules}
                                minLength={2}
                                maxLength={30}
                                value={name}
                                style={{ fontSize: "14px", marginBottom: "8px" }}
                                iconSize={14}
                                messages={{
                                    minLength: lang.object_name_contain[0],
                                    maxLength: lang.object_name_contain[1],
                                }}
                            />
                            <CustomButton type="submit" style="dark" variant="contained" text={lang.save} disabled={disabledButton} />
                        </form>
                    </FormControl>
                    <Box></Box>
                </Box>
            )}
        </>
    );
};

EditObject.propTypes = {
    isLoading: PropTypes.bool,
};

export default EditObject;

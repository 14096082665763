import { createSlice } from "@reduxjs/toolkit";
import { getVendors, getCameraModels, getCameraInfo } from "./vendors_operations";

export const initialState = {
    isLoadingVendors: false,
    getVendorsStatus: null,
    getCameraModelsStatus: null,
    getCameraInfoStatus: null,
    vendorsList: [],
    cameraModelsList: [],
    cameraInfo: [],
};

const vendorsSlice = createSlice({
    name: "vendors",
    initialState,
    extraReducers: {
        // get vendors
        [getVendors.pending]: (state) => {
            state.isLoadingVendors = true;
            state.getVendorsStatus = null;
            state.getCameraModelsStatus = null;
            state.getCameraInfoStatus = null;
        },
        [getVendors.fulfilled]: (state, { payload }) => {
            state.isLoadingVendors = false;
            if (payload) {
                state.getVendorsStatus = payload.status;
                state.vendorsList = payload.data;
            }
        },
        [getVendors.rejected]: (state, { payload }) => {
            state.isLoadingVendors = false;
            if (payload) {
                state.getVendorsStatus = payload.status;
            }
        },
        // get camera models
        [getCameraModels.pending]: (state) => {
            state.isLoadingVendors = false;
            state.getVendorsStatus = null;
            state.getCameraModelsStatus = null;
            state.getCameraInfoStatus = null;
        },
        [getCameraModels.fulfilled]: (state, { payload }) => {
            state.isLoadingVendors = false;
            if (payload) {
                state.getCameraModelsStatus = payload.status;
                state.cameraModelsList = payload.data;
            }
        },
        [getCameraModels.rejected]: (state, { payload }) => {
            state.isLoadingVendors = false;
            if (payload) {
                state.getCameraModelsStatus = payload.status;
            }
        },
        // get camera info
        [getCameraInfo.pending]: (state) => {
            state.isLoadingVendors = true;
            state.getVendorsStatus = null;
            state.getCameraModelsStatus = null;
            state.getCameraInfoStatus = null;
        },
        [getCameraInfo.fulfilled]: (state, { payload }) => {
            state.isLoadingVendors = false;
            if (payload) {
                state.cameraInfo = payload.data;
                state.getCameraInfoStatus = payload.status;
            }
        },
        [getCameraInfo.rejected]: (state, { payload }) => {
            state.isLoadingVendors = false;
            if (payload) {
                state.getCameraInfoStatus = payload.status;
            }
        },
    },
});

export const vendorsReducer = vendorsSlice.reducer;

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import VanillaToasts from "../../components/toasts/vanillatoasts";
import { translations } from "../../constants/translation";
import { /* setSuccessNotification */ setWarningNotification, setErrorNotification, getLanguage } from "../local_storage";

// get vendors
export const getVendors = createAsyncThunk("vendors/getVendors", async ({ token, _isLogin, _showNotifications }) => {
    const { error500, vendors_has_been_received, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.get("/api/v1/camera_info", { headers: { "X-Auth-Key": token } });

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: vendors_has_been_received,
                    type: "success",
                    timeout: 5000,
                });
            }

            if (_isLogin) {
                // setSuccessNotification(status, vendors_has_been_received);
            }
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });

            if (_isLogin) {
                setWarningNotification(status);
            }
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });

        if (_isLogin) {
            setErrorNotification(e);
        }

        return { status: e.response.status };
    }
});

// get camera models
export const getCameraModels = createAsyncThunk("vendors/getCameraModels", async ({ token, vendor, _isLogin, _showNotifications }) => {
    const { error500, vendor_models_has_been_received, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.get(`/api/v1/camera_info/@${vendor}`, { headers: { "X-Auth-Key": token } });

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: vendor_models_has_been_received,
                    type: "success",
                    timeout: 5000,
                });
            }

            if (_isLogin) {
                // setSuccessNotification(status, vendor_models_has_been_received);
            }
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });

            if (_isLogin) {
                setWarningNotification(status);
            }
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });

        if (_isLogin) {
            setErrorNotification(e);
        }

        return { status: e.response.status };
    }
});

// get camera models
export const getCameraInfo = createAsyncThunk("vendors/getCameraInfo", async ({ token, modelID, _isLogin, _showNotifications }) => {
    const { error500, camera_info_has_been_received, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.get(`/api/v1/camera_info/${modelID}`, { headers: { "X-Auth-Key": token } });

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: camera_info_has_been_received,
                    type: "success",
                    timeout: 5000,
                });
            }

            if (_isLogin) {
                // setSuccessNotification(status, camera_info_has_been_received);
            }
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });

            if (_isLogin) {
                setWarningNotification(status);
            }
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });

        if (_isLogin) {
            setErrorNotification(e);
        }

        return { status: e.response.status };
    }
});

// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// MUI
import { Box } from "@mui/material";
// Components
import Header from "../components/Header";
import Loader from "../components/Loader";
// IMG
import success_activation_icon from "../img/success_green.svg";
import warning_icon from "../img/warning_black.svg";
// Other
import { enLang } from "../redux/global/global_selectors";
import { activateUserStatus, isLogin } from "../redux/auth/auth_selectors";
import { activateUser } from "../redux/auth/auth_operations";
import { translations } from "../constants/translation";

const Activate = ({ isLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _isLogin = useSelector(isLogin);
    const _enLang = useSelector(enLang);
    const statusCode = useSelector(activateUserStatus);
    const lang = _enLang ? translations.en : translations.ukr;

    const { activation, activate_profile_message, success_activation, activate_profile_warning_message } = lang;

    useEffect(() => {
        if (_isLogin) {
            navigate(-1);
            return;
        }

        const token = window.location.href.split("?")[1];

        if (token) {
            dispatch(activateUser({ token }));
        }
    }, []);

    const renderContent = () => {
        if (statusCode) {
            if (statusCode === 200) {
                return (
                    <Box className="success_activation">
                        <img className="success_activation_icon" src={success_activation_icon} alt="success activation" />
                        <span className="success_activation_message">{success_activation}</span>
                    </Box>
                );
            } else {
                return (
                    <Box className="warning_activation">
                        <img className="warning_activation_icon" src={warning_icon} alt="warning activation" />
                        <span className="warning_activation_message">{activate_profile_warning_message}</span>
                    </Box>
                );
            }
        } else {
            return <span className="activate_profile_message">{activate_profile_message}</span>;
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="activate">
                    <Header text={activation} showBackArrow={false} />
                    {renderContent()}
                    <Box className="activate_helper_block"></Box>
                </Box>
            )}
        </>
    );
};

Activate.propTypes = {
    isLoading: PropTypes.bool,
};

export default Activate;

export const user = (state) => state.auth.user;
export const signUpUserEmail = (state) => state.auth.signUpUserEmail;
export const logInUserEmail = (state) => state.auth.logInUserEmail;
export const isLogin = (state) => state.auth.isLogin;
export const isLoadingAuth = (state) => state.auth.isLoadingAuth;
export const logInMessage = (state) => state.auth.logInMessage;
export const logInStatus = (state) => state.auth.logInStatus;
export const signUpStatus = (state) => state.auth.signUpStatus;
export const prolongTokenStatus = (state) => state.auth.prolongTokenStatus;
export const activateUserStatus = (state) => state.auth.activateUserStatus;
export const deleteUserStatus = (state) => state.auth.deleteUserStatus;
export const recoverPasswordRequestStatus = (state) => state.auth.recoverPasswordRequestStatus;
export const recoverPasswordCheckTokenStatus = (state) => state.auth.recoverPasswordCheckTokenStatus;
export const recoverPasswordApplyStatus = (state) => state.auth.recoverPasswordApplyStatus;
export const editPasswordStatus = (state) => state.auth.editPasswordStatus;

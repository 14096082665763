export const streamsList = (state) => state.streams.streamsList;
export const isLoadingStreams = (state) => state.streams.isLoadingStreams;
export const streamStatus = (state) => state.streams.streamStatus;
export const createStreamStatus = (state) => state.streams.createStreamStatus;
export const createStreamMessage = (state) => state.streams.createStreamMessage;
export const editStreamStatus = (state) => state.streams.editStreamStatus;
export const deleteStreamStatus = (state) => state.streams.deleteStreamStatus;
export const getStreamURLStatus = (state) => state.streams.getStreamURLStatus;
export const streamURL = (state) => state.streams.streamURL;
export const streamSlots = (state) => state.streams.streamSlots;
export const getStreamSlotsStatus = (state) => state.streams.getStreamSlotsStatus;
export const createClipStatus = (state) => state.streams.createClipStatus;
export const clipURL = (state) => state.streams.clipURL;
export const sendEmailApplicationStatus = (state) => state.streams.sendEmailApplicationStatus;

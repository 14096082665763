export const routes = {
    home: "/",
    objects: "/objects",
    authorization: "/authorization", // should work without login
    createProfile: "/create_profile", // should work without login
    addObject: "/add_object",
    addCamera: "/add_camera",
    qrScanner: "/qr_scanner",
    camerasManualAdding: "/cameras_manual_adding",
    objectsManualAdding: "/objects_manual_adding",
    login: "/login", // should work without login
    forgotPassword: "/forgot_password", // should work without login
    otp: "/otp",
    editMail: "/edit_mail",
    editPhone: "/edit_phone",
    editObject: "/edit_object",
    editCamera: "/edit_camera", // warning
    editPassword: "/edit_password",
    profile: "/profile",
    deleteProfile: "/delete_profile",
    notifications: "/notifications",
    settings: "/settings",
    cameras: "/cameras",
    watchStream: "/watch_stream",
    watchClip: "/watch_clip",
    activate: "/activate", // should work without login
    recover: "/recover", // should work without login
    recover_pass_form: "/recover_pass_form", // should work without login
    password_changed_page: "/password_changed_page", // should work without login
};

// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// Components
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import CustomButton from "../components/CustomButton";
import Loader from "../components/Loader";
// Libs
import last from "lodash.last";
// Material
import { FormControl, Box, Autocomplete, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Other
import { editStream } from "../redux/streams/streams_operations";
import { getUserIP, testPort } from "../redux/service/service_operations";
import { clearUserIP } from "../redux/service/service_slice";
import { isLogin } from "../redux/auth/auth_selectors";
import { showNotifications, enLang } from "../redux/global/global_selectors";
import { objectsList } from "../redux/objects/objects_selectors";
import { editStreamStatus } from "../redux/streams/streams_selectors";
import { userIP } from "../redux/service/service_selectors";
import { getVendors, getCameraModels, getCameraInfo } from "../redux/vendors/vendors_operations";
import { vendorsList, cameraModelsList, cameraInfo } from "../redux/vendors/vendors_selectors";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const EditCamera = ({ isLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const _isLogin = useSelector(isLogin);
    const _showNotifications = useSelector(showNotifications);
    const _enLang = useSelector(enLang);
    const _userIP = useSelector(userIP);
    const statusCode = useSelector(editStreamStatus);
    const _vendorsList = useSelector(vendorsList);
    const _cameraModelsList = useSelector(cameraModelsList);
    const _cameraInfo = useSelector(cameraInfo);
    const _objectsList = useSelector(objectsList);

    const { id, modelID, token, source, codec, retention, groupName } = location.state || {};
    const { camera_vendor_name: vendorName, name: modelName } = _cameraInfo[0] || [];

    const editCameraInfo = {
        cameraLogin: source?.split("//")[1].split(":")[0],
        cameraPassword: source?.split("//")[1].split(":")[1].split("@")[0],
        cameraIP: source?.split("//")[1].split(":")[1].split("@")[1],
        cameraPort: source?.split("//")[1].split(":")[2].split("/")[0],
        cameraSuffix: "/" + last(source?.split("//")),
    };

    const [producer, setProducer] = useState("");
    const [model, setModel] = useState("");
    const [newModelID, setNewModelID] = useState(modelID);
    const [cameraLogin, setCameraLogin] = useState(editCameraInfo.cameraLogin);
    const [cameraPassword, setCameraPassword] = useState(editCameraInfo.cameraPassword);
    const [cameraIP, setCameraIP] = useState(editCameraInfo.cameraIP);
    const [cameraPort, setCameraPort] = useState(editCameraInfo.cameraPort);
    const [cameraSuffix, setCameraSuffix] = useState(editCameraInfo.cameraSuffix);
    // const [cameraRetention, setCameraRetention] = useState(retention || null);
    const [cameraCodec, setCameraCodec] = useState(codec || null);
    const [disabledTestPortButton, setDisabledTestPortButton] = useState(true);
    const [disabledButton, setDisabledButton] = useState(true);

    const lang = _enLang ? translations.en : translations.ukr;

    const { cameras, home } = routes;
    const {
        name,
        _id,
        edit_camera,
        _retention,
        save,
        days,
        _producer,
        _model,
        _camera_login,
        _camera_password,
        _camera_ip,
        _camera_port,
        _camera_suffix,
        group_id,
        other,
        getIP,
        _testPort,
        _camera_codec,
        _default,
        ip_input_hint,
        port_input_hint,
        rtsp_url_input_hint,
    } = lang;

    // const retentionValues = [`1 ${day}`, `3 ${days}`, `7 ${days}`, `14 ${days}`];
    const producerValues = [..._vendorsList, other];
    const codecValues = ["h265", _default];
    const modelValues = [..._cameraModelsList.map(({ name }) => name), other];
    const retentionDefaultValue = days + ": " + retention / 24;
    const groupIdDefaultValue = _objectsList
        .map((object) => {
            if (object.name === groupName) {
                return `${name}: ${object.name}. ${_id}: ${object.id}`;
            }
        })
        .find((object) => Boolean(object) === true);

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }

        dispatch(getVendors({ token, _isLogin }));
        dispatch(getCameraInfo({ token, modelID, _isLogin }));
    }, []);

    useEffect(() => {
        setProducer(vendorName);
        setModel(modelName);
    }, [vendorName, modelName]);

    useEffect(() => {
        if (producer) {
            dispatch(getCameraModels({ token, _isLogin, vendor: producer }));
        }
    }, [producer]);

    useEffect(() => {
        if (statusCode === 200) {
            navigate(cameras);
            return;
        }
        return () => dispatch(clearUserIP());
    }, [statusCode]);

    useEffect(() => {
        if (cameraIP && cameraPort) {
            setDisabledTestPortButton(false);
        } else {
            setDisabledTestPortButton(true);
        }
    }, [cameraIP, cameraPort]);

    useEffect(() => {
        if (producer && model && cameraLogin && cameraPassword && cameraIP && cameraPort && cameraSuffix /*&& cameraRetention*/) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [producer, model, cameraLogin, cameraPassword, cameraIP, cameraPort, cameraSuffix /*cameraRetention*/]);

    useEffect(() => {
        setCameraIP(_userIP || editCameraInfo.cameraIP);
    }, [_userIP]);

    // const retentionDefaultValue = () => {
    //     if (cameraRetention === 1) {
    //         return cameraRetention + " " + day;
    //     }

    //     return cameraRetention + " " + days;
    // };

    const getUserIpHandler = () => {
        dispatch(getUserIP({ token, _isLogin, _showNotifications }));
        setCameraIP(_userIP);
    };

    const testPortHandler = () => {
        dispatch(testPort({ addr: cameraIP, port: Number(cameraPort), token, _isLogin }));
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const id = e.target.id;
        const content = e.target.innerText;

        /*if (id.includes("retention")) {
            setCameraRetention(first(content.split(" ")));
        } else*/ if (id.includes("codec")) {
            if (content === _default) {
                setCameraCodec(null);
            } else {
                setCameraCodec(content);
            }
        } else if (id.includes("producer")) {
            setProducer(content);
            setModel("");
            setCameraSuffix("");
            if (cameraSuffix && content === other) setCameraSuffix("");
        } else if (id.includes("model")) {
            setModel(content);
            if (cameraSuffix && content === other) setCameraSuffix("");
            _cameraModelsList.forEach(({ name, source, id }) => {
                if (content === name) {
                    setCameraSuffix(source);
                    setNewModelID(id);
                }
            });
        } else {
            switch (name) {
                case "camera_login":
                    setCameraLogin(value);
                    break;
                case "camera_password":
                    setCameraPassword(value);
                    break;
                case "camera_ip":
                    setCameraIP(value);
                    break;
                case "camera_port":
                    setCameraPort(value);
                    break;
                case "camera_suffix":
                    setCameraSuffix(value);
                    break;
                default:
                    return;
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(
            editStream({
                source: `rtsp://${cameraLogin}:${cameraPassword}@${cameraIP}:${cameraPort}/${cameraSuffix}`,
                source_internal: "",
                // retention: Number(cameraRetention),
                codec: cameraCodec,
                token,
                id,
                camera_model_id: Number(newModelID),
                _isLogin,
                _showNotifications,
            })
        );
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="edit_camera">
                    <Header text={edit_camera} />
                    <FormControl fullWidth>
                        <form onSubmit={handleSubmit}>
                            <Box className="edit_camera_input_wrapper">
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    disableClearable
                                    id="combo-box-demo producer"
                                    className="edit_camera_autocomplete"
                                    options={producerValues}
                                    name="producer"
                                    renderInput={(params) => <TextField {...params} label={_producer} />}
                                    value={producer || null}
                                    onChange={handleChange}
                                />
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    disableClearable
                                    id="combo-box-demo model"
                                    className="edit_camera_autocomplete"
                                    options={modelValues}
                                    name="model"
                                    renderInput={(params) => <TextField {...params} label={_model} />}
                                    value={model || null}
                                    onChange={handleChange}
                                />
                                <TextInput
                                    id="camera_login"
                                    label={_camera_login}
                                    name="camera_login"
                                    value={cameraLogin}
                                    onChange={handleChange}
                                />
                                <TextInput
                                    id="camera_password"
                                    label={_camera_password}
                                    name="camera_password"
                                    value={cameraPassword}
                                    onChange={handleChange}
                                />
                                <Box className="camera_ip_wrapper">
                                    <Box className="camera_ip_wrapper_helper">
                                        <TextInput
                                            className="text_input"
                                            id="camera_ip"
                                            label={_camera_ip}
                                            name="camera_ip"
                                            onChange={handleChange}
                                            value={cameraIP}
                                            rightZeroBorder
                                            hint
                                        />
                                        <LoadingButton
                                            className="get_user_ip_button"
                                            loading={false}
                                            variant="outlined"
                                            onClick={getUserIpHandler}
                                        >
                                            {getIP}
                                        </LoadingButton>
                                    </Box>
                                    <span className="camera_ip_wrapper_hint">{ip_input_hint}</span>
                                </Box>
                                <Box className="camera_port_wrapper">
                                    <Box className="camera_port_wrapper_helper">
                                        <TextInput
                                            id="camera_port"
                                            label={_camera_port}
                                            name="camera_port"
                                            value={cameraPort}
                                            onChange={handleChange}
                                            rightZeroBorder
                                            hint
                                        />
                                        <LoadingButton
                                            className={"test_port_button " + (disabledTestPortButton ? "disabled" : "")}
                                            loading={false}
                                            variant="outlined"
                                            onClick={testPortHandler}
                                            disabled={disabledTestPortButton}
                                        >
                                            {_testPort}
                                        </LoadingButton>
                                    </Box>
                                    <span className="camera_port_wrapper_hint">{port_input_hint}</span>
                                </Box>
                                <Box className="camera_suffix_wrapper">
                                    <TextInput
                                        id="camera_suffix"
                                        label={_camera_suffix}
                                        name="camera_suffix"
                                        value={cameraSuffix}
                                        onChange={handleChange}
                                        hint
                                    />
                                    <span className="camera_suffix_wrapper_hint">{rtsp_url_input_hint}</span>
                                </Box>
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    disableClearable
                                    id="combo-box-demo codec"
                                    className="edit_camera_autocomplete"
                                    options={codecValues}
                                    name="codec"
                                    renderInput={(params) => <TextField {...params} label={_camera_codec} />}
                                    defaultValue={codec || _default}
                                    onChange={handleChange}
                                />
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    disableClearable
                                    id="combo-box-demo retention"
                                    className="edit_camera_autocomplete"
                                    options={[]}
                                    name="retention"
                                    renderInput={(params) => <TextField {...params} label={_retention} />}
                                    defaultValue={retentionDefaultValue}
                                    onChange={handleChange}
                                    disabled
                                />
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    disableClearable
                                    id="combo-box-demo group_id"
                                    className="edit_camera_autocomplete"
                                    options={[]}
                                    name="group_id"
                                    renderInput={(params) => <TextField {...params} label={group_id} />}
                                    defaultValue={groupIdDefaultValue}
                                    onChange={handleChange}
                                    disabled
                                />
                            </Box>
                            <Box className="global_button_wrapper">
                                <CustomButton type="submit" style="dark" variant="contained" text={save} disabled={disabledButton} />
                            </Box>
                        </form>
                    </FormControl>
                    <Box></Box>
                </Box>
            )}
        </>
    );
};

EditCamera.propTypes = {
    isLoading: PropTypes.bool,
};

export default EditCamera;

// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// Components
import Header from "../components/Header";
import Loader from "../components/Loader";

// MUI
import { Box } from "@mui/material";
// Other
import { enLang } from "../redux/global/global_selectors";
import { isLogin } from "../redux/auth/auth_selectors";
import { translations } from "../constants/translation";
// Image
import success_password_changed_icon from "../img/success_green.svg";

const PasswordChangedPage = ({ isLoading }) => {
    const navigate = useNavigate();
    const _isLogin = useSelector(isLogin);
    const _enLang = useSelector(enLang);
    const lang = _enLang ? translations.en : translations.ukr;

    const { password_recovery, password_successfull_changed } = lang;

    useEffect(() => {
        if (_isLogin) {
            navigate(-1);
            return;
        }
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="password_changed_page">
                    <Header text={password_recovery} showBackArrow={false} />
                    <Box className="password_changed_page_content">
                        <img className="password_changed_page_icon" src={success_password_changed_icon} alt="success" />
                        <span className="password_changed_page_message">{password_successfull_changed}</span>
                    </Box>

                    <Box className="password_changed_page_helper_block"></Box>
                </Box>
            )}
        </>
    );
};

PasswordChangedPage.propTypes = {
    isLoading: PropTypes.bool,
};

export default PasswordChangedPage;

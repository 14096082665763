// React
import React from "react";
import PropTypes from "prop-types";
// Material
import { TextField } from "@mui/material";

const TextInput = ({ id, type, placeholder, label, name, onChange, disabled, error, value, defaultValue, rightZeroBorder, hint }) => {
    return (
        <TextField
            id={error ? "outlined-error-helper-text-" + id : "outlined-basic-" + id}
            className={"input " + (rightZeroBorder ? "right_zero_border " : "") + (hint ? "input_with_hint" : "")}
            error={error}
            type={type}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            label={label}
            variant="outlined"
            disabled={disabled}
            value={value}
            defaultValue={defaultValue}
            fullWidth
        />
    );
};

TextInput.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    rightZeroBorder: PropTypes.bool,
    hint: PropTypes.bool,
};

export default TextInput;

// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// Components
import Header from "../components/Header";
import Loader from "../components/Loader";
import VideoPlayer from "../components/VideoPlayer";
import DateTimePicker from "../components/DateTimePicker";
import CustomButton from "../components/CustomButton";
// Libs
import { Box, CircularProgress } from "@mui/material";
// Other
import { getStreamURL, createClip } from "../redux/streams/streams_operations";
import { user, isLogin } from "../redux/auth/auth_selectors";
import { getStreamURLStatus, createClipStatus, streamURL } from "../redux/streams/streams_selectors";
import { enLang } from "../redux/global/global_selectors";
import { videoLoaderStyle } from "../styles/components/style";
import { updateStreamTokenInterval } from "../constants/helpers";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const WatchStream = ({ isLoading }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _isLogin = useSelector(isLogin);
    const { token } = useSelector(user);
    const _enLang = useSelector(enLang);
    const _getStreamURLStatus = useSelector(getStreamURLStatus);
    const _createClipStatus = useSelector(createClipStatus);
    const _streamURL = useSelector(streamURL);
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [disabledButton, setDisabledButton] = useState(true);
    const streamId = location.state?.id;
    const streamRetention = location.state?.retention;
    const lang = _enLang ? translations.en : translations.ukr;

    const { watchClip, home } = routes;

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }

        if (_createClipStatus === 200) {
            navigate(watchClip);
            return;
        }

        dispatch(getStreamURL({ token, id: streamId, _isLogin }));

        let timer = null;

        timer = setInterval(() => {
            dispatch(getStreamURL({ token, id: streamId, _isLogin }));
        }, updateStreamTokenInterval);

        return () => clearInterval(timer);
    }, [_createClipStatus]);

    useEffect(() => {
        if (startTime && endTime) {
            setDisabledButton(false);
        }
    }, [startTime, endTime]);

    const getClipHandler = () => {
        dispatch(createClip({ token, id: streamId, t_start: startTime, t_end: endTime, _isLogin }));
    };

    const errorHandler = (error) => {
        if (error && error.isTrusted) {
            console.log({ error: error.isTrusted });
            dispatch(getStreamURL({ token, id: streamId, _isLogin }));
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="watch_stream">
                    <Header text={lang.watch_stream} />
                    <Box className="watch_stream_video_wrapper">
                        {_getStreamURLStatus === 200 ? (
                            <VideoPlayer url={_streamURL} errorHandler={errorHandler} />
                        ) : (
                            <CircularProgress sx={videoLoaderStyle} color="info" />
                        )}
                    </Box>
                    <Box className="watch_stream_date_picker">
                        <DateTimePicker label={lang.start_time} setStartTime={setStartTime} streamRetention={streamRetention} />
                        <DateTimePicker label={lang.end_time} setEndTime={setEndTime} streamRetention={streamRetention} />
                    </Box>
                    <Box>
                        <CustomButton
                            type="button"
                            style="dark"
                            variant="contained"
                            text={lang.create_clip}
                            onClick={getClipHandler}
                            disabled={disabledButton}
                        />
                    </Box>
                    <Box></Box>
                </Box>
            )}
        </>
    );
};

WatchStream.propTypes = {
    isLoading: PropTypes.bool,
};

export default WatchStream;

import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./auth/auth_slice";
import { streamsReducer } from "./streams/streams_slice";
import { globalReducer } from "./global/global_slice";
import { objectsReducer } from "./objects/objects_slice";
import { serviceReducer } from "./service/service_slice";
import { vendorsReducer } from "./vendors/vendors_slice";

const store = configureStore({
    reducer: {
        auth: authReducer,
        streams: streamsReducer,
        global: globalReducer,
        objects: objectsReducer,
        service: serviceReducer,
        vendors: vendorsReducer,
    },
});

export default store;

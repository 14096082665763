// React
import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
// Material
import { OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// Other
import { enLang } from "../redux/global/global_selectors";
import { translations } from "../constants/translation";

const PasswordInput = ({ id, name, onChange, error, placeholder }) => {
    const [showPassword, setShowPassword] = useState(false);
    const _enLang = useSelector(enLang);

    const lang = _enLang ? translations.en : translations.ukr;
    const { password } = lang;

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <OutlinedInput
            fullWidth
            id={(error ? "outlined-error-helper-text" : "outlined-adornment-password") + " " + id}
            className="input"
            error={error}
            type={showPassword ? "text" : "password"}
            name={name}
            onChange={onChange}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            }
            placeholder={placeholder ? placeholder : password}
        />
    );
};

PasswordInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
};

export default PasswordInput;

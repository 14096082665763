// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// Components
import Header from "../components/Header";
import Loader from "../components/Loader";
// Libraries
// import { QrReader } from "react-qr-reader";
// Other
import { enLang } from "../redux/global/global_selectors";
import { isLogin } from "../redux/auth/auth_selectors";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const QRScanner = ({ isLoading }) => {
    // eslint-disable-next-line no-unused-vars
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const _isLogin = useSelector(isLogin);
    const _enLang = useSelector(enLang);
    const lang = _enLang ? translations.en : translations.ukr;

    const { scan_qr } = lang;
    const { home } = routes;

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }

        if (data) {
            window.location.replace(data);
        }
    }, [data]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="qr_scanner_wrapper">
                    <Header text={scan_qr} />
                    <div className="qr_scanner_camera_wrapper">
                        {/* <QrReader
                onResult={(result, error) => {
                    if (result) {
                        setData(result.text);
                    }

                    if (!error) {
                        console.info(error);
                    }
                }}
                constraints={{
                    facingMode: "environment", //switch between "environment"(back camera) and "user" (front camera)
                    aspectRatio: 1,
                    frameRate: 15,
                }}
            /> */}
                    </div>
                    <div className="qr_scanner_helper_block"></div>
                </div>
            )}
        </>
    );
};

QRScanner.propTypes = {
    isLoading: PropTypes.bool,
};

export default QRScanner;

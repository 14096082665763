import { createSlice } from "@reduxjs/toolkit";
import {
    getStreamsList,
    getObjectStreamsList,
    createStream,
    deleteStream,
    editStream,
    getStreamURL,
    getStreamSlots,
    createClip,
    sendEmailApplication,
} from "./streams_operations";

export const initialState = {
    isLoadingStreams: false,
    streamsList: [],
    streamSlots: [],
    streamStatus: null,
    createStreamStatus: null,
    createStreamMessage: "",
    editStreamStatus: null,
    deleteStreamStatus: null,
    getStreamURLStatus: null,
    getStreamSlotsStatus: null,
    createClipStatus: null,
    sendEmailApplicationStatus: null,
    streamURL: "",
    clipURL: "",
};

const streamsSlice = createSlice({
    name: "streams",
    initialState,
    extraReducers: {
        // get streams list
        [getStreamsList.pending]: (state) => {
            state.isLoadingStreams = true;
            state.streamStatus = null;
            state.createStreamStatus = null;
            state.createStreamMessage = "";
            state.editStreamStatus = null;
            state.deleteStreamStatus = null;
            state.getStreamURLStatus = null;
            state.getStreamSlotsStatus = null;
            state.createClipStatus = null;
            state.sendEmailApplicationStatus = null;
        },
        [getStreamsList.fulfilled]: (state, { payload }) => {
            state.isLoadingStreams = false;
            if (payload) {
                if (payload.status === 200) {
                    state.streamsList = payload.data;
                    state.streamStatus = payload.status;
                } else {
                    state.streamStatus = payload.status;
                }
            }
        },
        [getStreamsList.rejected]: (state, { payload }) => {
            state.isLoadingStreams = false;
            if (payload) {
                state.streamStatus = payload.status;
            }
        },
        // get object streams list
        [getObjectStreamsList.pending]: (state) => {
            state.isLoadingStreams = true;
            state.streamStatus = null;
            state.createStreamStatus = null;
            state.createStreamMessage = "";
            state.editStreamStatus = null;
            state.deleteStreamStatus = null;
            state.getStreamURLStatus = null;
            state.getStreamSlotsStatus = null;
            state.createClipStatus = null;
            state.sendEmailApplicationStatus = null;
        },
        [getObjectStreamsList.fulfilled]: (state, { payload }) => {
            state.isLoadingStreams = false;
            if (payload) {
                if (payload.status === 200) {
                    state.streamsList = payload.data;
                    state.streamStatus = payload.status;
                } else {
                    state.streamStatus = payload.status;
                }
            }
        },
        [getObjectStreamsList.rejected]: (state, { payload }) => {
            state.isLoadingStreams = false;
            if (payload) {
                state.streamStatus = payload.status;
            }
        },
        // create stream
        [createStream.pending]: (state) => {
            state.isLoadingStreams = true;
            state.streamStatus = null;
            state.createStreamStatus = null;
            state.createStreamMessage = "";
            state.editStreamStatus = null;
            state.deleteStreamStatus = null;
            state.getStreamURLStatus = null;
            state.getStreamSlotsStatus = null;
            state.createClipStatus = null;
            state.sendEmailApplicationStatus = null;
        },
        [createStream.fulfilled]: (state, { payload }) => {
            state.isLoadingStreams = false;
            state.createStreamStatus = payload.status;
            state.createStreamMessage = payload.message;
            if (payload && payload.status === 200) {
                state.createStreamStatus = payload.status;
            }
        },
        [createStream.rejected]: (state, { payload }) => {
            state.isLoadingStreams = false;
            if (payload) {
                state.createStreamStatus = payload.status;
                state.createStreamMessage = payload.message;
            }
        },
        // delete stream
        [deleteStream.pending]: (state) => {
            state.isLoadingStreams = true;
            state.streamStatus = null;
            state.createStreamStatus = null;
            state.createStreamMessage = "";
            state.editStreamStatus = null;
            state.deleteStreamStatus = null;
            state.getStreamURLStatus = null;
            state.getStreamSlotsStatus = null;
            state.createClipStatus = null;
            state.sendEmailApplicationStatus = null;
        },
        [deleteStream.fulfilled]: (state, { payload }) => {
            if (payload) {
                state.deleteStreamStatus = payload.status;
            }
            state.isLoadingStreams = false;
        },
        [deleteStream.rejected]: (state, { payload }) => {
            if (payload) {
                state.deleteStreamStatus = payload.status;
            }
            state.isLoadingStreams = false;
        },
        // edit stream
        [editStream.pending]: (state) => {
            state.isLoadingStreams = true;
            state.streamStatus = null;
            state.createStreamStatus = null;
            state.createStreamMessage = "";
            state.editStreamStatus = null;
            state.deleteStreamStatus = null;
            state.getStreamURLStatus = null;
            state.getStreamSlotsStatus = null;
            state.createClipStatus = null;
            state.sendEmailApplicationStatus = null;
        },
        [editStream.fulfilled]: (state, { payload }) => {
            state.isLoadingStreams = false;
            if (payload) {
                state.editStreamStatus = payload.status;
            }
        },
        [editStream.rejected]: (state, { payload }) => {
            state.isLoadingStreams = false;
            if (payload) {
                state.editStreamStatus = payload.status;
            }
        },
        // get stream URL
        [getStreamURL.pending]: (state) => {
            state.isLoadingStreams = false;
            state.streamStatus = null;
            state.createStreamStatus = null;
            state.createStreamMessage = "";
            state.editStreamStatus = null;
            state.deleteStreamStatus = null;
            state.getStreamURLStatus = null;
            state.getStreamSlotsStatus = null;
            state.createClipStatus = null;
            state.sendEmailApplicationStatus = null;
        },
        [getStreamURL.fulfilled]: (state, { payload }) => {
            state.isLoadingStreams = false;
            if (payload && payload.status === 200) {
                state.streamURL = payload.data.url;
                state.getStreamURLStatus = payload.status;
            } else {
                state.getStreamURLStatus = payload.status;
            }
        },
        [getStreamURL.rejected]: (state) => {
            state.isLoadingStreams = false;
        },
        // get stream slots
        [getStreamSlots.pending]: (state) => {
            state.isLoadingStreams = true;
            state.streamStatus = null;
            state.createStreamStatus = null;
            state.createStreamMessage = "";
            state.editStreamStatus = null;
            state.deleteStreamStatus = null;
            state.getStreamURLStatus = null;
            state.getStreamSlotsStatus = null;
            state.createClipStatus = null;
            state.sendEmailApplicationStatus = null;
        },
        [getStreamSlots.fulfilled]: (state, { payload }) => {
            state.isLoadingStreams = false;
            if (payload) {
                state.getStreamSlotsStatus = payload.status;
                state.streamSlots = payload.data;
            }
        },
        [getStreamSlots.rejected]: (state, { payload }) => {
            state.isLoadingStreams = false;
            if (payload) {
                state.getStreamSlotsStatus = payload.status;
            }
        },
        // create clip
        [createClip.pending]: (state) => {
            state.isLoadingStreams = true;
            state.streamStatus = null;
            state.createStreamStatus = null;
            state.createStreamMessage = "";
            state.editStreamStatus = null;
            state.deleteStreamStatus = null;
            state.getStreamURLStatus = null;
            state.getStreamSlotsStatus = null;
            state.createClipStatus = null;
            state.sendEmailApplicationStatus = null;
        },
        [createClip.fulfilled]: (state, { payload }) => {
            state.isLoadingStreams = false;
            if (payload && payload.status === 200) {
                state.clipURL = payload.url;
                state.createClipStatus = payload.status;
            } else {
                state.createClipStatus = payload.status;
            }
        },
        [createClip.rejected]: (state, { payload }) => {
            state.isLoadingStreams = false;
            if (payload) {
                state.createClipStatus = payload.status;
            }
        },
        // send email application
        [sendEmailApplication.pending]: (state) => {
            state.isLoadingStreams = true;
            state.streamStatus = null;
            state.createStreamStatus = null;
            state.createStreamMessage = "";
            state.editStreamStatus = null;
            state.deleteStreamStatus = null;
            state.getStreamURLStatus = null;
            state.getStreamSlotsStatus = null;
            state.createClipStatus = null;
            state.sendEmailApplicationStatus = null;
        },
        [sendEmailApplication.fulfilled]: (state, { payload }) => {
            state.isLoadingStreams = false;
            if (payload) {
                state.sendEmailApplicationStatus = payload.status;
            }
        },
        [sendEmailApplication.rejected]: (state, { payload }) => {
            state.isLoadingStreams = false;
            if (payload) {
                state.sendEmailApplicationStatus = payload.status;
            }
        },
    },
});

export const streamsReducer = streamsSlice.reducer;

// React
import React from "react";
// Img
import logo from "../img/logo.svg";

const Logo = () => {
    return <img className="logo" src={logo} alt="logo" />;
};

export default Logo;

// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
// Components
import Header from "../components/Header";
import CustomButton from "../components/CustomButton";
import DropDown from "../components/DropDown";
import BasicModal from "../components/Modal";
import Loader from "../components/Loader";
// Material
import { Box, Card, CardActionArea, CardMedia, Typography } from "@mui/material";
// Img
import preview_icon from "../img/preview.svg";
// Other
import { getStreamsList, getObjectStreamsList, deleteStream } from "../redux/streams/streams_operations";
import { streamsList, deleteStreamStatus } from "../redux/streams/streams_selectors";
import { user, isLogin } from "../redux/auth/auth_selectors";
import { enLang } from "../redux/global/global_selectors";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const Cameras = ({ isLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const _isLogin = useSelector(isLogin);
    const _streamsList = useSelector(streamsList);
    const _deleteStreamStatus = useSelector(deleteStreamStatus);
    const { token } = useSelector(user);
    const _enLang = useSelector(enLang);
    const [openModal, setOpenModal] = useState(false);
    const [cameraID, setCameraID] = useState(null);

    const objectId = location.state?.id;
    const rtn = Number(location.state) * 24;
    const lang = _enLang ? translations.en : translations.ukr;

    const { _id, camera, cameras, delete_camera, add_camera } = lang;
    const { home, addCamera, watchStream } = routes;

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }

        if (objectId) {
            dispatch(getObjectStreamsList({ token, id: objectId, _isLogin }));
        } else {
            dispatch(getStreamsList({ token, _isLogin }));
        }
    }, [dispatch, _deleteStreamStatus]);

    const watchStreamHandler = (id, retention) => {
        navigate(watchStream, { state: { id, retention } });
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="cameras">
                    <Header text={cameras} showBackArrow={false}></Header>
                    <Box className="cameras_content">
                        <Box className="cameras_card_holder">
                            {_streamsList.map(({ id, camera_model_id, source, codec, group_name, retention }, i) => (
                                <Card key={"camera_" + id} className="cameras_card">
                                    <CardActionArea className="cameras_card_action_area">
                                        <CardMedia
                                            className="cameras_card_img"
                                            component="img"
                                            image={preview_icon}
                                            alt="stream"
                                            onClick={() => watchStreamHandler(id, retention)}
                                        />
                                        <Box className="cameras_card_info_wrapper" onClick={() => watchStreamHandler(id, retention)}>
                                            <Typography className="camera_serial_number">{camera + ": " + (i + 1)}</Typography>
                                            <Typography className="camera_name">{"STREAM " + _id + ": " + id}</Typography>
                                            <Typography className="camera_name">{"MODEL " + _id + ": " + camera_model_id}</Typography>
                                            <Typography className="camera_name">{"SRC: " + source.slice(0, 20) + "..."}</Typography>
                                            <Typography className="camera_name">{"CODEC: " + codec}</Typography>
                                            <Typography className="camera_name">{"GROUP: " + group_name}</Typography>
                                            <Typography className="camera_name">{"RTN: " + (retention || rtn)}</Typography>
                                        </Box>
                                        <DropDown
                                            setOpenModal={setOpenModal}
                                            setID={setCameraID}
                                            source={source}
                                            id={id}
                                            modelID={camera_model_id}
                                            codec={codec}
                                            retention={retention || rtn}
                                            groupName={group_name}
                                            token={token}
                                        />
                                    </CardActionArea>
                                    <BasicModal
                                        openModal={openModal}
                                        setOpenModal={setOpenModal}
                                        text={delete_camera}
                                        id={cameraID}
                                        deleteStream={deleteStream}
                                    />
                                </Card>
                            ))}
                        </Box>
                        <Box className="global_button_wrapper">
                            <Link to={addCamera}>
                                <CustomButton style="dark" variant="contained" text={add_camera} />
                            </Link>
                        </Box>
                    </Box>
                    <Box></Box>
                </Box>
            )}
        </>
    );
};

Cameras.propTypes = {
    isLoading: PropTypes.bool,
};

export default Cameras;

import { createSlice } from "@reduxjs/toolkit";
import { getAppVersion } from "./global_operations";
import { setLanguage, getLanguage, setNotificationsStatus, getNotificationsStatus } from "../local_storage";

export const initialState = {
    showNotifications: getNotificationsStatus(),
    enLang: getLanguage() === "en",
    ukrLang: getLanguage() === "ukr",
    appVersion: String(null),
};

const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        showNotifications: (state) => {
            state.showNotifications = !state.showNotifications;
            setNotificationsStatus(state.showNotifications);
        },
        enableEnLang: (state) => {
            state.enLang = true;
            state.ukrLang = false;
            setLanguage("en");
        },
        enableUkrLang: (state) => {
            state.enLang = false;
            state.ukrLang = true;
            setLanguage("ukr");
        },
    },
    extraReducers: {
        // get app version
        [getAppVersion.pending]: () => {},
        [getAppVersion.fulfilled]: (state, { payload }) => {
            if (payload && payload.status === 200) {
                state.appVersion = payload.data.version;
            }
        },
        [getAppVersion.rejected]: () => {},
    },
});

export const { showNotifications, enableEnLang, enableUkrLang } = globalSlice.actions;

export const globalReducer = globalSlice.reducer;

// React
import React from "react";
// Material
import { CircularProgress } from "@mui/material";

const Loader = () => (
    <div className="loader">
        <CircularProgress size={150} />
    </div>
);

export default Loader;

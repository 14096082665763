// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// Components
import Header from "../components/Header";
import PasswordInput from "../components/PasswordInput";
import CustomButton from "../components/CustomButton";
import Loader from "../components/Loader";
// MUI
import { Box } from "@mui/material";
// Other
import { enLang } from "../redux/global/global_selectors";
import { translations } from "../constants/translation";
import { recoverPasswordApply } from "../redux/auth/auth_operations";
import { recoverPasswordApplyStatus, isLogin } from "../redux/auth/auth_selectors";
import { resetRecoverPasswordApplyStatus } from "../redux/auth/auth_slice";
import { routes } from "../constants/routes";

const RecoverPasswordForm = ({ isLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const _isLogin = useSelector(isLogin);
    const _enLang = useSelector(enLang);
    const statusCode = useSelector(recoverPasswordApplyStatus);
    const [password, setPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [disabledButton, setDisabledButton] = useState(true);
    const [correctPassword, setCorrectPassword] = useState(false);

    const lang = _enLang ? translations.en : translations.ukr;
    const { token } = location.state || {};
    const { password_changed_page } = routes;
    const { password_recovery, repeat_password, new_password, set, password_must_contain } = lang;

    useEffect(() => {
        if (_isLogin) {
            navigate(-1);
            return;
        }

        if (statusCode === 200) {
            navigate(password_changed_page);
            return;
        } else if (statusCode === 400) {
            setCorrectPassword(true);
        }

        if (password && confirmedPassword) {
            if (password === confirmedPassword) {
                setDisabledButton(false);
            } else {
                setDisabledButton(true);
            }
        }

        return () => dispatch(resetRecoverPasswordApplyStatus());
    }, [password, confirmedPassword, statusCode]);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (correctPassword) {
            setCorrectPassword(false);
            dispatch(resetRecoverPasswordApplyStatus());
        }

        switch (name) {
            case "password":
                setPassword(value);
                break;
            case "confirm_password":
                setConfirmedPassword(value);
                break;
            default:
                return;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(recoverPasswordApply({ token, password }));
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="recover_password">
                    <Header text={password_recovery} showBackArrow={false} />
                    <Box className="recover_password_form_wrapper">
                        {correctPassword && <span className="recover_password_error_text">{password_must_contain}</span>}
                        <form className="recover_password_form" onSubmit={handleSubmit}>
                            <Box>
                                <PasswordInput
                                    id="password"
                                    name="password"
                                    placeholder={new_password}
                                    error={correctPassword}
                                    onChange={handleChange}
                                />
                                <PasswordInput
                                    id="confirm_password"
                                    name="confirm_password"
                                    placeholder={repeat_password}
                                    error={correctPassword}
                                    onChange={handleChange}
                                />
                            </Box>
                            <CustomButton type="submit" style="dark" variant="contained" text={set} disabled={disabledButton} />
                        </form>
                    </Box>
                    <Box className="recover_password_helper_block"></Box>
                </Box>
            )}
        </>
    );
};

RecoverPasswordForm.propTypes = {
    isLoading: PropTypes.bool,
};

export default RecoverPasswordForm;

// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// MUI
import { Box } from "@mui/material";
// Components
import Header from "../components/Header";
import Loader from "../components/Loader";
// IMG
import success_icon from "../img/success_green.svg";
import warning_icon from "../img/warning_black.svg";
// Other
import { enLang } from "../redux/global/global_selectors";
import { recoverPasswordCheckToken } from "../redux/auth/auth_operations";
import { recoverPasswordCheckTokenStatus, isLogin } from "../redux/auth/auth_selectors";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const Recover = ({ isLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const statusCode = useSelector(recoverPasswordCheckTokenStatus);
    const _isLogin = useSelector(isLogin);
    const _enLang = useSelector(enLang);
    const lang = _enLang ? translations.en : translations.ukr;
    const token = window.location.href.split("?")[1];

    const { password_recovery, password_recovery_message, token_has_been_expired } = lang;
    const { recover_pass_form } = routes;

    useEffect(() => {
        if (_isLogin) {
            navigate(-1);
            return;
        }

        if (statusCode === 200) {
            navigate(recover_pass_form, { state: { token } });
            return;
        }

        if (token) {
            dispatch(recoverPasswordCheckToken({ token }));
        }
    }, [dispatch, token, statusCode === 200]);

    const renderContent = () => {
        if (statusCode === 403) {
            return (
                <Box className="recover_password_content">
                    <img className="recover_password_content_icon" src={warning_icon} alt="recover password success icon" />
                    <span className="recover_password_content_message">{token_has_been_expired}</span>
                </Box>
            );
        }

        return (
            <Box className="recover_password_content">
                <img className="recover_password_content_icon" src={success_icon} alt="recover password success icon" />
                <span className="recover_password_content_message">{password_recovery_message}</span>
            </Box>
        );
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="recover_password">
                    <Header text={password_recovery} showBackArrow={false} />
                    {renderContent()}
                    <Box className="recover_password_helper_block"></Box>
                </Box>
            )}
        </>
    );
};

Recover.propTypes = {
    isLoading: PropTypes.bool,
};

export default Recover;

// React
import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
// Material
import { Box, Modal } from "@mui/material";
// Components
import CustomButton from "../components/CustomButton";
// Other
import { user, isLogin } from "../redux/auth/auth_selectors";
import { showNotifications, enLang } from "../redux/global/global_selectors";
import { translations } from "../constants/translation";

const BasicModal = ({ openModal, setOpenModal, text, id, deleteStream, deleteObject }) => {
    const dispatch = useDispatch();
    const { token } = useSelector(user);
    const _isLogin = useSelector(isLogin);
    const _showNotifications = useSelector(showNotifications);
    const _enLang = useSelector(enLang);

    const lang = _enLang ? translations.en : translations.ukr;
    const { _id, recovery_impossible, _delete, decline } = lang;

    const deleteCameraHandler = () => {
        if (deleteStream) {
            dispatch(deleteStream({ id, token, _isLogin, _showNotifications }));
            setOpenModal(false);
            return;
        }

        if (deleteObject) {
            dispatch(deleteObject({ id, token, _isLogin, _showNotifications }));
            setOpenModal(false);
            return;
        }
    };

    const closeModalHandler = () => {
        setOpenModal(false);
    };

    return (
        <div>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal_window">
                    <span className="modal_window_text">{text}</span>
                    <span className="modal_window_serial_no">
                        {_id}: {id}
                    </span>
                    <span className="modal_window_warning">{recovery_impossible}</span>
                    <CustomButton style="dark" variant="contained" text={_delete} onClick={deleteCameraHandler} />
                    <CustomButton style="transparent" variant="text" text={decline} onClick={closeModalHandler} />
                </Box>
            </Modal>
        </div>
    );
};

BasicModal.propTypes = {
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    deleteStream: PropTypes.func,
    deleteObject: PropTypes.func,
    text: PropTypes.string,
    id: PropTypes.number,
};

export default BasicModal;

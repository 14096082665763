export const translations = {
    en: {
        edit: "Edit",
        _delete: "Delete",
        _id: "ID",
        recovery_impossible: "Recovery will be impossible",
        password: "Password",
        new_password: "New password",
        old_password: "Old password",
        set: "Set",
        scan_qr: "Scan qr code",
        manual_adding: "Manual adding",
        add_object_location: "Add Object",
        name: "Name",
        address: "Address",
        add_more: "Add more Objects",
        save: "Save",
        register: "Register",
        _login: "Login",
        camera: "Camera",
        cameras: "Cameras",
        delete_camera: "Delete Camera",
        add_camera: "Add camera",
        add_cameras: "Add cameras",
        edit_camera: "Edit camera",
        _source: "Source",
        source_internal: "Source internal",
        _retention: "Retention",
        create_account: "Create account",
        account: "Account",
        _email: "Email",
        submit: "Submit",
        delete_profile: "Delete account",
        cautions: "Cautions",
        edit_email: "Edit E-mail",
        edit_password: "Edit password",
        send: "Send",
        edit_object: "Edit Object",
        edit_phone_number: "Edit Phone number",
        forgot_password: "Forgot password?",
        notifications: "Notifications",
        object: "Object",
        locals_objects: "Objects",
        delete_object: "Delete Object",
        code: "Code",
        enter_the_code: "Enter the code",
        confirm: "Confirm",
        send_code_again: "Send code again",
        page_not_found: "PAGE NOT FOUND",
        redirect_to_homepage: "I am redirecting you to home page...",
        profile: "Profile",
        logout: "Logout",
        set_english_language: "Set English language",
        set_ukrainian_language: "Set Ukrainian language",
        settings: "Settings",
        watch_stream: "Watch stream",
        watch_clip: "Watch clip",
        create_clip: "Create clip",
        start_time: "Start time",
        end_time: "End time",
        decline: "Decline",
        group_id: "Object ID",
        hours: "hours",
        days: "days",
        hour: "hour",
        day: "day",
        activation: "Activation",
        password_recovery: "Password recovery",
        repeat_password: "Repeat password",
        _producer: "Camera vendor",
        _model: "Camera model",
        _camera_login: "Camera login",
        _camera_password: "Camera password",
        _camera_ip: " Camera IP",
        _camera_port: "Camera port",
        _camera_suffix: "RTSP camera URL ",
        _camera_codec: "Camera codec",
        other: "Other",
        getIP: "Get IP",
        _testPort: "Test port",
        enter: "Login",
        registration: "Registration",
        _default: "Default",
        page_under_development: "The page is under development!",
        period: "Period",
        download_clip: "Download clip",
        order: "Order",
        demo: "Demo",
        minimal: "Minimal",
        optimal: "Optimal",
        premium: "Premium",
        available: "Available",
        used: "Used",
        tariff_plans: "Tariff plans:",
        version: "Version:",
        no_internet_connection: "No internet connection!",
        // messages
        email_already_exists: "Email already exists!",
        object_name_contain: ["Object name contains at least 2 characters.", "Object name contains no more than 30 characters."],
        password_must_contain: "The password must be at least 8 characters long and contain at least one letter and number!",
        password_contains: [
            "Password contains at least 8 characters.",
            "Password contains at least one number.",
            "Password contains at least one letter.",
        ],
        user_is_not_active_error_text: "Inactive account! To activate, follow the link that was sent to the email.",
        token_has_been_expired: "The password reset link has already been used or has expired!",
        password_recovery_message: "Please follow the link that was sent to the specified email!",
        success_activation: "The account has been successfully activated!",
        activate_profile_warning_message: "Maybe something went wrong. Try logging in to your account.",
        delete_all_cameras_warning: "All cameras must be deleted before deleting the account!",
        terms_of_use: "I agree to the terms of use of the web application.",
        invalid_credentials_error_text: "Invalid login or password!",
        activate_profile_message: "Activate your profile by following the link we sent you via email.",
        edit_phone_helper_text: "Enter new Mobile phone number and we will send you a code to confirm it.",
        forgot_password_helper_text:
            "Enter the email address specified in the account, where an email will be sent with a link to create a new password.",
        edit_mail_helper_text: "Enter new E-mail and we wi will send you a code to confirm it.",
        delete_text_warning:
            "After deletion, you will be able to use the associated email to register a new account. Any data (objects, cameras, settings, etc.) from a deleted account cannot be restored!",
        password_successfull_changed: "Password successfully set! Use it to log in to your account.",
        error500:
            "An unexpected error occurred. Please try again. If the problem continues, feel free to contact our technical support team.",
        ip_input_hint: "Enter manually or select automatically if you are on the same local network as the camera.",
        port_input_hint: "Enter the port chosen for this camera on the router.",
        rtsp_url_input_hint: "Choose from the base or add manually according to the camera model.",
        no_such_email_exists: "The specified e-mail was not registered!",
        this_service_is_unavailable: "This service is currently unavailable. We will contact you shortly to add this service!",
        // error and warning popup messages
        success: "Success!",
        warning: "Warning!",
        error: "Error!",
        something_went_wrong: "Oops! Something went wrong!",
        login_has_been_successful: "Login has been successful!",
        logout_has_been_successful: "Logout has been successful!",
        signup_has_been_successful: "Signup has been successful!",
        user_activation_has_been_successful: "User activation has been successful!",
        user_has_been_deleted: "User has been deleted!",
        token_prolongation_has_been_successful: "Token prolongation has been successful!",
        token_has_been_checked: "Token has been checked!",
        password_has_been_edited: "Password has been edited!",
        objects_has_been_loaded: "Objects has been loaded!",
        object_has_been_created: "Object has been created!",
        object_has_been_edited: "Object has been edited!",
        object_has_been_deleted: "Object has been deleted!",
        ip_has_been_obtained: "IP has been obtained!",
        port_is_open: "Port is open!",
        streams_has_been_loaded: "Streams has been loaded!",
        stream_has_been_created: "Stream has been created!",
        stream_has_been_deleted: "Stream has been deleted!",
        stream_has_been_edited: "Stream has been edited!",
        stream_url_has_been_created: "Stream URL has been created!",
        clip_url_has_been_created: "Clip  URL has been created!",
        stream_slots_has_been_received: "Stream slots has been received!",
        vendors_has_been_received: "Vendors has been received!",
        vendor_models_has_been_received: "Vendor models has been received!",
        camera_info_has_been_received: "Camera info has been received!",
        email_has_been_sent: "Email has been sent!",
    },

    ukr: {
        edit: "Редагувати",
        _delete: "Видалити",
        _id: "ID",
        recovery_impossible: "Відновлення буде неможливим",
        password: "Пароль",
        new_password: "Новий пароль",
        old_password: "Старий пароль",
        set: "Встановити",
        scan_qr: "Сканувати qr-код",
        manual_adding: "Додавання вручну",
        add_object_location: "Додати об'єкт",
        name: "Назва",
        address: "Адреса",
        add_more: "Додати більше об'єктів",
        save: "Зберегти",
        register: "Зареєструватися",
        _login: "Вхід",
        camera: "Камера",
        cameras: "Камери",
        delete_camera: "Видалити камеру",
        add_camera: "Додати камеру",
        add_cameras: "Додати камери",
        edit_camera: "Редагувати камеру",
        _source: "Джерело",
        source_internal: "Внутрішнє джерело",
        _retention: "Збереження",
        create_account: "Створити акаунт",
        account: "Акаунт",
        _email: "Е-пошта",
        submit: "Надіслати",
        delete_profile: "Видалити акаунт",
        cautions: "Застереження",
        edit_email: "Змінити е-пошту",
        edit_password: "Змінити пароль",
        send: "Відправити",
        edit_object: "Редагувати об'єкт",
        edit_phone_number: "Змінити номер телефону",
        forgot_password: "Забули пароль?",
        notifications: "Сповіщення",
        object: "Об'єкт",
        locals_objects: "Об'єкти",
        delete_object: "Видалити об'єкт",
        code: "Код",
        enter_the_code: "Введіть код",
        confirm: "Підтвердити",
        send_code_again: "Надіслати код знову",
        page_not_found: "СТОРІНКА НЕ ЗНАЙДЕНА",
        redirect_to_homepage: "Перенаправляю вас на домашню сторінку...",
        profile: "Профіль",
        logout: "Вийти",
        set_english_language: "Встановити англійську мову",
        set_ukrainian_language: "Встановити українську мову",
        settings: "Налаштування",
        watch_stream: "Переглянути потік",
        watch_clip: "Переглянути кліп",
        create_clip: "Створити кліп",
        start_time: "Час початку",
        end_time: "Час кінця",
        decline: "Закрити",
        group_id: "ID об'єкта",
        hours: "годин",
        days: "дні",
        hour: "година",
        day: "день",
        activation: "Активація",
        password_recovery: "Відновлення паролю",
        repeat_password: "Повторіть пароль",
        _producer: "Виробник камери",
        _model: "Модель камери",
        _camera_login: "Логін камери",
        _camera_password: "Пароль камери",
        _camera_ip: "IP камери",
        _camera_port: "Порт камери",
        _camera_suffix: "RTSP URL камери",
        _camera_codec: "Кодек камери",
        other: "Іншa",
        getIP: "Отримати IP",
        _testPort: "Тест порту",
        enter: "Увійти",
        registration: "Реєстрація",
        _default: "По замовчуванню",
        page_under_development: "Сторінка на стадії розробки!",
        period: "Період",
        download_clip: "Завантажити кліп",
        order: "Замовити",
        demo: "Пробний",
        minimal: "Мінімальний",
        optimal: "Оптимальний",
        premium: "Преміум",
        available: "Доступно",
        used: "Використано",
        tariff_plans: "Тарифні плани:",
        version: "Версія:",
        no_internet_connection: "Немає інтернет з'єднання!",
        // messages
        email_already_exists: "Електронна пошта вже зареєстрована!",
        object_name_contain: ["Назва об'єкту повинна містити не менше 2 символів.", "Назва об'єкту повинна містити не більше 30 символів."],
        password_must_contain: "Пароль повинен бути не менше 8 символів та містити в собі хоча б одну букву і цифру!",
        password_contains: [
            "Пароль повинен містити не менше 8 символів.",
            "Пароль повинен містити хоча б одну цифру.",
            "Пароль повинен містити хоча б одну букву.",
        ],
        user_is_not_active_error_text:
            "Неактивний акаунт! Для активації перейдіть за посиланням, яке було відправлене на електронну пошту.",
        token_has_been_expired: "Посилання для відновлення паролю вже було використане або вийшло з терміну дії!",
        password_recovery_message: "Будь ласка, перейдіть за посиланням, яке було відправлене на вказану електронну пошту!",
        success_activation: "Акаунт успішно активований!",
        activate_profile_warning_message: "Можливо, щось пішло не так. Спробуйте залогуватись до акаунту.",
        delete_all_cameras_warning: "Перед видаленням акаунту необхідно видалити усі камери!",
        terms_of_use: "Я погоджуюсь з умовами користування веб-додатком.",
        invalid_credentials_error_text: "Невірний логін або пароль!",
        activate_profile_message: "Активуйте свій профіль перейшовши за посиланням, яке ми вам відправили на електронну пошту.",
        edit_phone_helper_text: "Введіть новий номер мобільного телефону, і ми надішлемо вам код для підтвердження.",
        forgot_password_helper_text:
            "Введіть електронну пошту, яка вказана в акаунті, на неї буде відправлений лист із посиланням для створення нового паролю.",
        edit_mail_helper_text: "Введіть нову адресу електронної пошти, і ми надішлемо вам код для підтвердження.",
        delete_text_warning:
            "Після видалення ви зможете використати прив’язану електронну пошту для реєстрації нового акаунту. Будь-які дані (об’єкти, камери, налаштування та інше) із видаленого акаунту не підлягають відновленню!",
        password_successfull_changed: "Пароль успішно встановлений! Використовуйте його для входу у свій акаунт.",
        error500:
            "Сталася неочікувана помилка. Будь ласка, спробуйте ще раз. Якщо проблема не зникла, зверніться до нашої служби технічної підтримки.",
        ip_input_hint: "Введіть вручну або виберіть автоматично, якщо знаходитесь в одній локальній мережі з камерою.",
        port_input_hint: "Введіть порт який обрали для цієї камери на роутері.",
        rtsp_url_input_hint: "Виберіть з бази або додайте вручну відповідно до моделі камери.",
        no_such_email_exists: "Вказана електронна пошта не була зареєстрована!",
        this_service_is_unavailable: "Наразі ця послуга недоступна. Найближчим часом ми зв'яжемося з вами, щоб додати дану послугу!",
        // error and warning popup messages
        success: "Успіх!",
        warning: "Попередження!",
        error: "Помилка!",
        something_went_wrong: "Ой! Щось пішло не так!",
        login_has_been_successful: "Логінізація успішна!",
        logout_has_been_successful: "Вилогування успішне!",
        signup_has_been_successful: "Реєстрація успішна!",
        user_activation_has_been_successful: "Активація користувача успішна!",
        user_has_been_deleted: "Користувача видалено!",
        token_prolongation_has_been_successful: "Дію токена продовжено!",
        token_has_been_checked: "Токен перевірено!",
        password_has_been_edited: "Пароль відредаговано!",
        objects_has_been_loaded: "Об'єкти завантажено!",
        object_has_been_created: "Об'єкт створено!",
        object_has_been_edited: "Об'єкт відредаговано!",
        object_has_been_deleted: "Об'єкт видалено!",
        ip_has_been_obtained: "IP отримано!",
        port_is_open: "Порт відкритий!",
        streams_has_been_loaded: "Потоки завантажено!",
        stream_has_been_created: "Потік створено!",
        stream_has_been_deleted: "Потік видалено!",
        stream_has_been_edited: "Потік відредаговано!",
        stream_url_has_been_created: "URL потоку створено!",
        clip_url_has_been_created: "URL кліпу створено!",
        stream_slots_has_been_received: "Потокові слоти отримано!",
        vendors_has_been_received: "Виробники отримані!",
        vendor_models_has_been_received: "Моделі отримані!",
        camera_info_has_been_received: "Інформація про камеру отримана!",
        email_has_been_sent: "Е-лист відправлено!",
    },
};

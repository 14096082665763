// React
import React from "react";
import PropTypes from "prop-types";
// Libs
import ReactPlayer from "react-player";

const VideoPlayer = ({ url, index, setIndex, errorHandler }) => {
    return (
        <ReactPlayer
            url={url}
            width="100%"
            height="100%"
            onEnded={() => setIndex(index + 1)}
            playsinline
            playing
            controls
            muted
            onError={errorHandler}
        />
    );
};

VideoPlayer.propTypes = {
    url: PropTypes.string,
    index: PropTypes.number,
    setIndex: PropTypes.func,
    errorHandler: PropTypes.func,
};

export default VideoPlayer;

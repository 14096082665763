import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import dayjs from "dayjs";
import "dayjs/locale/uk";
import "dayjs/locale/en";
import { enLang } from "../redux/global/global_selectors";

const DateTimePicker = ({ label, setStartTime, setEndTime, streamRetention }) => {
    const _enLang = useSelector(enLang);
    const locale = _enLang ? "en" : "uk";
    const subtractDays = streamRetention / 24;

    const changeHandler = (e) => {
        const time = e?.$d.getTime() / 1000;

        if (setStartTime) {
            setStartTime(time);
        }

        if (setEndTime) {
            setEndTime(time);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
            <DemoContainer components={["MobileDateTimePicker"]}>
                <DemoItem label={label}>
                    <MobileDateTimePicker
                        onChange={changeHandler}
                        minDate={dayjs().subtract(subtractDays, "day")}
                        maxDateTime={dayjs(new Date())}
                    />
                </DemoItem>
            </DemoContainer>
        </LocalizationProvider>
    );
};

DateTimePicker.propTypes = {
    label: PropTypes.string,
    setStartTime: PropTypes.func,
    setEndTime: PropTypes.func,
    streamRetention: PropTypes.number,
};

export default DateTimePicker;

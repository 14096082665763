// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// Components
import TextInput from "../components/TextInput";
import CustomButton from "../components/CustomButton";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Title from "../components/Title";
import Logo from "../components/Logo";
// Material
import { FormControl, Box } from "@mui/material";
// Other
import { enLang } from "../redux/global/global_selectors";
import { isLogin } from "../redux/auth/auth_selectors";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const EditPhone = ({ isLoading }) => {
    const navigate = useNavigate();
    const _isLogin = useSelector(isLogin);
    const _enLang = useSelector(enLang);
    const lang = _enLang ? translations.en : translations.ukr;

    const { edit_phone_number, edit_phone_helper_text, send } = lang;
    const { otp, home } = routes;

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="edit_phone">
                    <Header text={edit_phone_number} />
                    <Box className="edit_phone_logo_wrapper">
                        <Logo />
                        <Title tag="h1" text="NVISION" />
                    </Box>
                    <FormControl fullWidth>
                        <span className="edit_phone_helper_text">{edit_phone_helper_text}</span>
                        <Box className="edit_phone_input_wrapper">
                            <TextInput id="phone" type="number" />
                        </Box>
                        <Box className="edit_phone_button_wrapper">
                            <Link to={otp}>
                                <CustomButton style="dark" variant="contained" text={send} />
                            </Link>
                        </Box>
                    </FormControl>
                    <Box></Box>
                </Box>
            )}
        </>
    );
};

EditPhone.propTypes = {
    isLoading: PropTypes.bool,
};

export default EditPhone;

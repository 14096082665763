// React
import React from "react";
import PropTypes from "prop-types";
// Material
import { Button } from "@mui/material";

const CustomButton = ({ type, style, variant, text, onClick, disabled }) => {
    return (
        <Button
            fullWidth
            className={"button " + style + (disabled ? "disabled" : "")}
            variant={variant}
            type={type}
            onClick={onClick}
            disabled={disabled}
        >
            {text}
        </Button>
    );
};

CustomButton.propTypes = {
    type: PropTypes.oneOf(["button", "submit", "reset"]),
    variant: PropTypes.string,
    style: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

export default CustomButton;

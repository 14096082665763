// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// Components
import Title from "../components/Title";
import TextInput from "../components/TextInput";
import PasswordInput from "../components/PasswordInput";
import CustomButton from "../components/CustomButton";
import Logo from "../components/Logo";
import Loader from "../components/Loader";
// Libs
import { FormControl, Box, FormControlLabel, Checkbox } from "@mui/material";
import PasswordChecklist from "react-password-checklist";
// Other
import { signUp } from "../redux/auth/auth_operations";
import { signUpStatus, signUpUserEmail, isLogin } from "../redux/auth/auth_selectors";
import { enLang } from "../redux/global/global_selectors";
import { setSignUpUserEmail, resetSignUpUserEmail, resetSignUpStatus } from "../redux/auth/auth_slice";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";
import { emailFormat, passwordRules } from "../constants/helpers";

const CreateProfile = ({ isLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const statusCode = useSelector(signUpStatus);
    const _signUpUserEmail = useSelector(signUpUserEmail);
    const _isLogin = useSelector(isLogin);
    const _enLang = useSelector(enLang);
    const [email, setEmail] = useState(_signUpUserEmail);
    const [password, setPassword] = useState("");
    const [checked, setChecked] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
    const [correctPassword, setCorrectPassword] = useState(false);

    const lang = _enLang ? translations.en : translations.ukr;

    const { create_account, _email, register, terms_of_use, email_already_exists, password_must_contain, password_contains } = lang;
    const { activate } = routes;

    useEffect(() => {
        if (_isLogin) {
            navigate(-1);
            return;
        }

        if (statusCode === 200) {
            navigate(activate);
            dispatch(resetSignUpUserEmail());
        } else if (statusCode === 409) {
            setEmailAlreadyExists(true);
        } else if (statusCode === 400) {
            setCorrectPassword(true);
        }

        if (email && email.match(emailFormat) && password && checked) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }

        return () => dispatch(resetSignUpStatus());
    }, [dispatch, statusCode, email, password, checked]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(signUp({ email, password }));
        dispatch(setSignUpUserEmail({ email }));
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const isChecked = e.target.checked;

        switch (name) {
            case "email":
                setEmail(value);
                if (emailAlreadyExists) {
                    setEmailAlreadyExists(false);
                    dispatch(resetSignUpStatus());
                }
                break;
            case "password":
                setPassword(value);
                if (correctPassword) {
                    setCorrectPassword(false);
                    dispatch(resetSignUpStatus());
                }
                break;
            case "terms_of_use":
                setChecked(isChecked);
                break;
            default:
                return;
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="create_profile">
                    <Box className="create_profile_logo_wrapper">
                        <Logo />
                        <Title tag="h1" text="NVISION" />
                    </Box>
                    <Box className="create_profile_title">
                        <Title tag="h2" text={create_account} />
                    </Box>
                    <FormControl fullWidth>
                        {emailAlreadyExists && <span className="create_profile_error_text">{email_already_exists}</span>}
                        {correctPassword && <span className="create_profile_error_text">{password_must_contain}</span>}
                        <form onSubmit={handleSubmit}>
                            <Box className="create_profile_input_wrapper">
                                <TextInput
                                    id="email"
                                    type="text"
                                    placeholder={_email}
                                    name="email"
                                    onChange={handleChange}
                                    value={email}
                                    error={emailAlreadyExists}
                                />
                                <PasswordInput id="password" name="password" onChange={handleChange} error={correctPassword} />
                                <PasswordChecklist
                                    rules={passwordRules}
                                    minLength={8}
                                    value={password}
                                    style={{ fontSize: "14px", marginBottom: "8px" }}
                                    iconSize={14}
                                    messages={{
                                        minLength: password_contains[0],
                                        number: password_contains[1],
                                        letter: password_contains[2],
                                    }}
                                />
                                <Box className="create_profile_terms_of_use_wrapper">
                                    <FormControlLabel
                                        id="terms_of_use"
                                        name="terms_of_use"
                                        className="create_profile_terms_of_use"
                                        control={<Checkbox onChange={handleChange} />}
                                    />
                                    <a href={location.origin + "/terms.pdf"} rel="noreferrer" target="_blank">
                                        {terms_of_use}
                                    </a>
                                </Box>
                            </Box>
                            <CustomButton type="submit" style="dark" variant="contained" text={register} disabled={disabledButton} />
                        </form>
                    </FormControl>
                </Box>
            )}
        </>
    );
};

CreateProfile.propTypes = {
    isLoading: PropTypes.bool,
};

export default CreateProfile;

import { createSlice } from "@reduxjs/toolkit";
import {
    logIn,
    logOut,
    signUp,
    prolongToken,
    activateUser,
    deleteUser,
    recoverPasswordRequest,
    recoverPasswordCheckToken,
    recoverPasswordApply,
    editPassword,
} from "./auth_operations";

const _token = localStorage.getItem("token");
const _email = localStorage.getItem("email");
const user_id = localStorage.getItem("user_id");

export const initialState = {
    user: {
        id: user_id || null,
        email: _email || "",
        token: _token || "",
    },
    signUpUserEmail: "",
    logInUserEmail: "",
    isLogin: _token && _email ? true : false,
    isLoadingAuth: false,
    logInMessage: "",
    logInStatus: null,
    signUpStatus: null,
    prolongTokenStatus: null,
    activateUserStatus: null,
    deleteUserStatus: null,
    recoverPasswordRequestStatus: null,
    recoverPasswordCheckTokenStatus: null,
    recoverPasswordApplyStatus: null,
    editPasswordStatus: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        resetLoginStatus: (state) => {
            state.logInStatus = null;
        },
        resetSignUpStatus: (state) => {
            state.signUpStatus = null;
        },
        resetRecoverPasswordRequestStatus: (state) => {
            state.recoverPasswordRequestStatus = null;
        },
        resetRecoverPasswordApplyStatus: (state) => {
            state.recoverPasswordApplyStatus = null;
        },
        resetEditPasswordStatus: (state) => {
            state.editPasswordStatus = null;
        },
        resetSignUpUserEmail: (state) => {
            state.signUpUserEmail = "";
        },
        resetLogInUserEmail: (state) => {
            state.logInUserEmail = "";
        },
        setSignUpUserEmail: (state, { payload }) => {
            state.signUpUserEmail = payload.email;
        },
        setLogInUserEmail: (state, { payload }) => {
            state.logInUserEmail = payload.email;
        },
    },
    extraReducers: {
        // login
        [logIn.pending]: (state) => {
            state.isLoadingAuth = true;
            state.logInStatus = null;
            state.signUpStatus = null;
            state.prolongTokenStatus = null;
            state.activateUserStatus = null;
            state.deleteUserStatus = null;
            state.recoverPasswordRequestStatus = null;
            state.recoverPasswordCheckTokenStatus = null;
            state.recoverPasswordApplyStatus = null;
            state.editPasswordStatus = null;
            state.logInMessage = "";
        },
        [logIn.fulfilled]: (state, { payload }) => {
            state.logInMessage = payload.message;
            if (payload && payload.status === 200) {
                state.isLoadingAuth = false;
                state.isLogin = true;
                state.user = { id: payload.data.user_id, email: payload.data.email, token: payload.data.token };
                state.logInStatus = payload.status;
            } else {
                state.isLoadingAuth = false;
                state.logInStatus = payload.status;
            }
        },
        [logIn.rejected]: (state, { payload }) => {
            state.isLoadingAuth = false;
            state.logInStatus = payload.status;
        },
        // logout
        [logOut.pending]: (state) => {
            state.isLoadingAuth = true;
            state.logInStatus = null;
            state.signUpStatus = null;
            state.prolongTokenStatus = null;
            state.activateUserStatus = null;
            state.deleteUserStatus = null;
            state.recoverPasswordRequestStatus = null;
            state.recoverPasswordCheckTokenStatus = null;
            state.recoverPasswordApplyStatus = null;
            state.editPasswordStatus = null;
            state.logInMessage = "";
        },
        [logOut.fulfilled]: (state, { payload }) => {
            if (payload && payload.status === 200) {
                state.isLoadingAuth = false;
                state.isLogin = false;
                state.user = {
                    id: null,
                    email: "",
                    token: "",
                };
            } else {
                state.isLoadingAuth = false;
            }
        },
        [logOut.rejected]: (state) => {
            state.isLoadingAuth = false;
        },
        // singup
        [signUp.pending]: (state) => {
            state.isLoadingAuth = true;
            state.logInStatus = null;
            state.signUpStatus = null;
            state.prolongTokenStatus = null;
            state.activateUserStatus = null;
            state.deleteUserStatus = null;
            state.recoverPasswordRequestStatus = null;
            state.recoverPasswordCheckTokenStatus = null;
            state.recoverPasswordApplyStatus = null;
            state.editPasswordStatus = null;
            state.logInMessage = "";
        },
        [signUp.fulfilled]: (state, { payload }) => {
            if (payload) {
                state.signUpStatus = payload.status;
            }
            state.isLoadingAuth = false;
        },
        [signUp.rejected]: (state, { payload }) => {
            if (payload) {
                state.signUpStatus = payload.status;
            }
            state.isLoadingAuth = false;
        },
        // prolong token
        [prolongToken.pending]: (state) => {
            state.isLoadingAuth = false;
            state.logInStatus = null;
            state.signUpStatus = null;
            state.prolongTokenStatus = null;
            state.activateUserStatus = null;
            state.deleteUserStatus = null;
            state.recoverPasswordRequestStatus = null;
            state.recoverPasswordCheckTokenStatus = null;
            state.recoverPasswordApplyStatus = null;
            state.editPasswordStatus = null;
            state.logInMessage = "";
        },
        [prolongToken.fulfilled]: (state, { payload }) => {
            if (payload) {
                state.prolongTokenStatus = payload.status;
            }
            state.isLoadingAuth = false;
        },
        [prolongToken.rejected]: (state, { payload }) => {
            if (payload) {
                state.prolongTokenStatus = payload.status;
            }
            state.isLoadingAuth = false;
        },
        // activate user
        [activateUser.pending]: (state) => {
            state.isLoadingAuth = true;
            state.logInStatus = null;
            state.signUpStatus = null;
            state.prolongTokenStatus = null;
            state.activateUserStatus = null;
            state.deleteUserStatus = null;
            state.recoverPasswordRequestStatus = null;
            state.recoverPasswordCheckTokenStatus = null;
            state.recoverPasswordApplyStatus = null;
            state.editPasswordStatus = null;
            state.logInMessage = "";
        },
        [activateUser.fulfilled]: (state, { payload }) => {
            if (payload) {
                state.activateUserStatus = payload.status;
            }
            state.isLoadingAuth = false;
        },
        [activateUser.rejected]: (state, { payload }) => {
            if (payload) {
                state.activateUserStatus = payload.status;
            }
            state.isLoadingAuth = false;
        },
        // delete user
        [deleteUser.pending]: (state) => {
            state.isLoadingAuth = true;
            state.logInStatus = null;
            state.signUpStatus = null;
            state.prolongTokenStatus = null;
            state.activateUserStatus = null;
            state.deleteUserStatus = null;
            state.recoverPasswordRequestStatus = null;
            state.recoverPasswordCheckTokenStatus = null;
            state.recoverPasswordApplyStatus = null;
            state.editPasswordStatus = null;
            state.logInMessage = "";
        },
        [deleteUser.fulfilled]: (state, { payload }) => {
            if (payload) {
                state.deleteUserStatus = payload.status;
                if (payload.status === 200) {
                    state.isLogin = false;
                    state.user = {
                        id: null,
                        email: "",
                        token: "",
                    };
                }
            }
            state.isLoadingAuth = false;
        },
        [deleteUser.rejected]: (state, { payload }) => {
            if (payload) {
                state.deleteUserStatus = payload.status;
            }
            state.isLoadingAuth = false;
        },
        // recover password request
        [recoverPasswordRequest.pending]: (state) => {
            state.isLoadingAuth = true;
            state.logInStatus = null;
            state.signUpStatus = null;
            state.prolongTokenStatus = null;
            state.activateUserStatus = null;
            state.deleteUserStatus = null;
            state.recoverPasswordRequestStatus = null;
            state.recoverPasswordCheckTokenStatus = null;
            state.recoverPasswordApplyStatus = null;
            state.editPasswordStatus = null;
            state.logInMessage = "";
        },
        [recoverPasswordRequest.fulfilled]: (state, { payload }) => {
            if (payload) {
                state.recoverPasswordRequestStatus = payload.status;
            }
            state.isLoadingAuth = false;
        },
        [recoverPasswordRequest.rejected]: (state, { payload }) => {
            if (payload) {
                state.recoverPasswordRequestStatus = payload.status;
            }
            state.isLoadingAuth = false;
        },
        // recover password check token
        [recoverPasswordCheckToken.pending]: (state) => {
            state.isLoadingAuth = true;
            state.logInStatus = null;
            state.signUpStatus = null;
            state.prolongTokenStatus = null;
            state.activateUserStatus = null;
            state.deleteUserStatus = null;
            state.recoverPasswordRequestStatus = null;
            state.recoverPasswordCheckTokenStatus = null;
            state.recoverPasswordApplyStatus = null;
            state.editPasswordStatus = null;
            state.logInMessage = "";
        },
        [recoverPasswordCheckToken.fulfilled]: (state, { payload }) => {
            if (payload) {
                state.recoverPasswordCheckTokenStatus = payload.status;
            }
            state.isLoadingAuth = false;
        },
        [recoverPasswordCheckToken.rejected]: (state, { payload }) => {
            if (payload) {
                state.recoverPasswordCheckTokenStatus = payload.status;
            }
            state.isLoadingAuth = false;
        },
        // recover password apply
        [recoverPasswordApply.pending]: (state) => {
            state.isLoadingAuth = true;
            state.logInStatus = null;
            state.signUpStatus = null;
            state.prolongTokenStatus = null;
            state.activateUserStatus = null;
            state.deleteUserStatus = null;
            state.recoverPasswordRequestStatus = null;
            state.recoverPasswordCheckTokenStatus = null;
            state.recoverPasswordApplyStatus = null;
            state.editPasswordStatus = null;
            state.logInMessage = "";
        },
        [recoverPasswordApply.fulfilled]: (state, { payload }) => {
            if (payload) {
                state.recoverPasswordApplyStatus = payload.status;
            }
            state.isLoadingAuth = false;
        },
        [recoverPasswordApply.rejected]: (state, { payload }) => {
            if (payload) {
                state.recoverPasswordApplyStatus = payload.status;
            }
            state.isLoadingAuth = false;
        },
        // edit password
        [editPassword.pending]: (state) => {
            state.isLoadingAuth = true;
            state.logInStatus = null;
            state.signUpStatus = null;
            state.prolongTokenStatus = null;
            state.activateUserStatus = null;
            state.deleteUserStatus = null;
            state.recoverPasswordRequestStatus = null;
            state.recoverPasswordCheckTokenStatus = null;
            state.recoverPasswordApplyStatus = null;
            state.editPasswordStatus = null;
            state.logInMessage = "";
        },
        [editPassword.fulfilled]: (state, { payload }) => {
            if (payload) {
                state.editPasswordStatus = payload.status;
            }
            state.isLoadingAuth = false;
        },
        [editPassword.rejected]: (state, { payload }) => {
            if (payload) {
                state.editPasswordStatus = payload.status;
            }
            state.isLoadingAuth = false;
        },
    },
});

export const {
    resetLoginStatus,
    resetSignUpStatus,
    resetRecoverPasswordRequestStatus,
    resetRecoverPasswordApplyStatus,
    resetSignUpUserEmail,
    resetLogInUserEmail,
    resetEditPasswordStatus,
    setSignUpUserEmail,
    setLogInUserEmail,
} = authSlice.actions;
export const authReducer = authSlice.reducer;

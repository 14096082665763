// React
import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
// Material
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Menu, MenuItem } from "@mui/material";
// Img
import dots_icon from "../img/dots.svg";
// Other
import { routes } from "../constants/routes";
import { enLang } from "../redux/global/global_selectors";
import { translations } from "../constants/translation";

const DropDown = ({ setOpenModal, setID, source, id, objectName, modelID, codec, retention, groupName, token }) => {
    const { pathname } = useLocation();
    const _enLang = useSelector(enLang);

    const lang = _enLang ? translations.en : translations.ukr;
    const { edit, _delete } = lang;

    const closeModalHandler = (e, popupState) => {
        setOpenModal(true);
        setID(Number(e.target.id));
        popupState.close();
    };

    const setLinkPath = () => {
        const { home, cameras, editObject, editCamera } = routes;

        const editObjectPath = pathname === home;
        const editCameraPath = pathname === cameras;

        if (editObjectPath) {
            return editObject;
        } else if (editCameraPath) {
            return editCamera;
        }
    };
    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
                <>
                    <img src={dots_icon} className="drop_down_menu_button" {...bindTrigger(popupState)} />
                    <Menu {...bindMenu(popupState)}>
                        <Link to={setLinkPath()} state={{ id, objectName, modelID, token, source, codec, retention, groupName }}>
                            <MenuItem onClick={popupState.close}>{edit}</MenuItem>
                        </Link>
                        <MenuItem id={id} onClick={(e) => closeModalHandler(e, popupState)}>
                            {_delete}
                        </MenuItem>
                    </Menu>
                </>
            )}
        </PopupState>
    );
};

DropDown.propTypes = {
    setOpenModal: PropTypes.func,
    setID: PropTypes.func,
    id: PropTypes.number,
    objectName: PropTypes.string,
    modelID: PropTypes.number,
    token: PropTypes.string,
    source: PropTypes.string,
    codec: PropTypes.oneOf([null, "h265"]),
    retention: PropTypes.number,
    groupName: PropTypes.string,
};

export default DropDown;

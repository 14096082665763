// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// Components
import TextInput from "../components/TextInput";
import CustomButton from "../components/CustomButton";
import Header from "../components/Header";
import Title from "../components/Title";
import Logo from "../components/Logo";
import Loader from "../components/Loader";
// Material
import { FormControl, Box } from "@mui/material";
// Other
import { user, editPasswordStatus, isLogin } from "../redux/auth/auth_selectors";
import { editPassword } from "../redux/auth/auth_operations";
import { resetEditPasswordStatus } from "../redux/auth/auth_slice";
import { showNotifications, enLang } from "../redux/global/global_selectors";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const EditPassword = ({ isLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [incorrectPassword, setIncorrectPassword] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const _showNotifications = useSelector(showNotifications);
    const statusCode = useSelector(editPasswordStatus);
    const { token } = useSelector(user);
    const _isLogin = useSelector(isLogin);
    const _enLang = useSelector(enLang);

    const lang = _enLang ? translations.en : translations.ukr;
    const { edit_password, password_must_contain, new_password, old_password, confirm } = lang;
    const { home } = routes;

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }

        if (statusCode) {
            if (statusCode === 200) {
                navigate(-1);
            } else {
                setIncorrectPassword(true);
            }
        }

        if (oldPassword && newPassword) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }

        return () => dispatch(resetEditPasswordStatus());
    }, [oldPassword, newPassword, statusCode]);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setIncorrectPassword(false);

        switch (name) {
            case "old_password":
                setOldPassword(value);
                break;
            case "new_password":
                setNewPassword(value);
                break;
            default:
                return;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(editPassword({ token, password: oldPassword, new_password: newPassword, _showNotifications }));
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="edit_password">
                    <Header text={edit_password} />
                    <Box className="edit_password_logo_wrapper">
                        <Logo />
                        <Title tag="h1" text="NVISION" />
                    </Box>
                    <FormControl fullWidth>
                        <span className="edit_password_helper_text">{password_must_contain}</span>
                        <form className="edit_password_form" onSubmit={handleSubmit}>
                            <Box className="edit_password_input_wrapper">
                                <TextInput
                                    id="old_password"
                                    name="old_password"
                                    placeholder={old_password}
                                    onChange={handleChange}
                                    error={incorrectPassword}
                                />
                                <TextInput
                                    id="new_password"
                                    name="new_password"
                                    placeholder={new_password}
                                    onChange={handleChange}
                                    error={incorrectPassword}
                                />
                            </Box>
                            <Box className="global_button_wrapper">
                                <CustomButton type="submit" style="dark" variant="contained" text={confirm} disabled={disabledButton} />
                            </Box>
                        </form>
                    </FormControl>
                    <Box></Box>
                </Box>
            )}
        </>
    );
};

EditPassword.propTypes = {
    isLoading: PropTypes.bool,
};

export default EditPassword;

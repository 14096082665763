export const setSuccessNotification = (status, message) => {
    const notificationObject = { status: "Success", message: message, code: status };
    localStorage.setItem("notification_" + (localStorage.length - 1), JSON.stringify(notificationObject));
};

export const setWarningNotification = (status) => {
    const notificationObject = { status: "Warning", message: "Something went wrong", code: status };
    localStorage.setItem("notification_" + (localStorage.length - 1), JSON.stringify(notificationObject));
};

export const setErrorNotification = (error) => {
    const notificationObject = { status: "Error", message: error.response.data, code: error.response.status };
    localStorage.setItem("notification_" + (localStorage.length - 1), JSON.stringify(notificationObject));
};

export const setLanguage = (language) => {
    localStorage.setItem("language", language);
};

export const getLanguage = () => {
    return localStorage.getItem("language");
};

export const removeToken = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
};

export const setNotificationsStatus = (status) => {
    localStorage.setItem("notifications_status", status);
};

export const getNotificationsStatus = () => {
    const status = localStorage.getItem("notifications_status");

    if (!status) {
        return false;
    } else if (status === "false") {
        return false;
    } else {
        return true;
    }
};

// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// Components
import Header from "../components/Header";
import Loader from "../components/Loader";
import VideoPlayer from "../components/VideoPlayer";
import CustomButton from "../components/CustomButton";
// Material
import { Box, Autocomplete, TextField } from "@mui/material";
// Libs
import moment from "moment";
import last from "lodash.last";
// Other
import { enLang } from "../redux/global/global_selectors";
import { isLogin } from "../redux/auth/auth_selectors";
import { clipURL } from "../redux/streams/streams_selectors";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const WatchClip = ({ isLoading }) => {
    const navigate = useNavigate();
    const _isLogin = useSelector(isLogin);
    const _enLang = useSelector(enLang);
    const _clipURL = useSelector(clipURL);
    const [index, setIndex] = useState(0);
    const [localLoader, setLocalLoader] = useState(false);
    const [timeLabels, setTimeLabels] = useState([]);
    const [timeLabelIndex, setTimeLabelIndex] = useState(null);
    const [disabledButton, setDisabledButton] = useState(true);

    const lang = _enLang ? translations.en : translations.ukr;

    const { watch_clip, period, download_clip } = lang;
    const { home } = routes;

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }
    }, []);

    useEffect(() => {
        const _timeLabels = [];

        (_clipURL || []).forEach((url) => {
            const startTime = +url.split("&")[1].split("=")[1] * 1000;
            const endTime = +url.split("&")[2].split("=")[1] * 1000;

            _timeLabels.push(moment(startTime).format("DD-MM-YYYY HH:mm") + "-" + moment(endTime).format("HH:mm"));
        });

        setTimeLabels(_timeLabels);
    }, [index]);

    const handleChange = (e) => {
        const id = e.target.id;
        const content = e.target.innerText;

        if (id.includes("period")) {
            timeLabels.forEach((label, index) => {
                if (label.includes(content)) {
                    setTimeLabelIndex(index);
                    setDisabledButton(false);
                }
            });
        }
    };

    const downloadHandler = () => {
        setLocalLoader(true);
        fetch(_clipURL[timeLabelIndex])
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `clip(${timeLabels[timeLabelIndex]}).mp4`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((e) => console.error(e))
            .finally(() => setLocalLoader(false));
    };

    const errorHandler = (error) => {
        if (error && error.isTrusted) {
            console.log({ error: error.isTrusted });
        }
    };

    return (
        <>
            {isLoading || localLoader ? (
                <Loader />
            ) : (
                <Box className="watch_clip">
                    <Header text={watch_clip} />
                    <VideoPlayer
                        url={_clipURL[index] ?? last(_clipURL)}
                        setInterval={setInterval}
                        index={index}
                        setIndex={setIndex}
                        errorHandler={errorHandler}
                    />
                    <Autocomplete
                        fullWidth
                        disablePortal
                        disableClearable
                        id="combo-box-demo period"
                        className="watch_clip_autocomplete"
                        options={timeLabels}
                        name="period"
                        renderInput={(params) => <TextField {...params} label={period} />}
                        onChange={handleChange}
                    />
                    <CustomButton
                        type="button"
                        style="dark"
                        variant="contained"
                        onClick={downloadHandler}
                        text={download_clip}
                        disabled={disabledButton}
                    />
                    <Box></Box>
                </Box>
            )}
        </>
    );
};

WatchClip.propTypes = {
    isLoading: PropTypes.bool,
};

export default WatchClip;

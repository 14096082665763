import { createSlice } from "@reduxjs/toolkit";
import { createObject, getObjectsList, deleteObject, editObject } from "./objects_operations";

export const initialState = {
    createObjectsStatus: null,
    getObjectsListStatus: null,
    deleteObjectStatus: null,
    editObjectStatus: null,
    isLoadingObjects: false,
    objectsList: [],
};

const objectsSlice = createSlice({
    name: "objects",
    initialState,
    extraReducers: {
        // create
        [createObject.pending]: (state) => {
            state.isLoadingObjects = true;
            state.createObjectsStatus = null;
            state.getObjectsListStatus = null;
            state.deleteObjectStatus = null;
            state.editObjectStatus = null;
        },
        [createObject.fulfilled]: (state, { payload }) => {
            state.isLoadingObjects = false;
            if (payload) {
                state.createObjectsStatus = payload.status;
            }
        },
        [createObject.rejected]: (state, { payload }) => {
            state.isLoadingObjects = false;
            if (payload) {
                state.createObjectsStatus = payload.status;
            }
        },
        // get
        [getObjectsList.pending]: (state) => {
            state.isLoadingObjects = true;
            state.createObjectsStatus = null;
            state.getObjectsListStatus = null;
            state.deleteObjectStatus = null;
            state.editObjectStatus = null;
        },
        [getObjectsList.fulfilled]: (state, { payload }) => {
            state.isLoadingObjects = false;
            if (payload) {
                state.getObjectsListStatus = payload.status;
                state.objectsList = payload.data;
            }
        },
        [getObjectsList.rejected]: (state, { payload }) => {
            state.isLoadingObjects = false;
            if (payload) {
                state.getObjectsListStatus = payload.status;
            }
        },
        // delete
        [deleteObject.pending]: (state) => {
            state.isLoadingObjects = true;
            state.createObjectsStatus = null;
            state.getObjectsListStatus = null;
            state.deleteObjectStatus = null;
            state.editObjectStatus = null;
        },
        [deleteObject.fulfilled]: (state, { payload }) => {
            state.isLoadingObjects = false;
            if (payload) {
                state.deleteObjectStatus = payload.status;
            }
        },
        [deleteObject.rejected]: (state, { payload }) => {
            state.isLoadingObjects = false;
            if (payload) {
                state.deleteObjectStatus = payload.status;
            }
        },
        // edit
        [editObject.pending]: (state) => {
            state.isLoadingObjects = true;
            state.createObjectsStatus = null;
            state.getObjectsListStatus = null;
            state.deleteObjectStatus = null;
            state.editObjectStatus = null;
        },
        [editObject.fulfilled]: (state, { payload }) => {
            state.isLoadingObjects = false;
            if (payload) {
                state.editObjectStatus = payload.status;
            }
        },
        [editObject.rejected]: (state, { payload }) => {
            state.isLoadingObjects = false;
            if (payload) {
                state.editObjectStatus = payload.status;
            }
        },
    },
});

export const objectsReducer = objectsSlice.reducer;

export const thStyle = {
    paddingBottom: "4px",
    paddingTop: "10px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#1D1D1D",
};

export const headerStyle = {
    paddingBottom: "2px",
    paddingTop: "10px",
    fontSize: "12px",
    color: "#808191",
};

export const tableLoaderStyle = { margin: "0 auto", paddingTop: "100px", paddingBottom: "100px" };
export const videoLoaderStyle = { margin: "0 auto", paddingTop: "80px", paddingBottom: "80px" };

/* eslint-disable no-unused-vars */
// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// Components
import Header from "../components/Header";
import Loader from "../components/Loader";
// Material
import { Box, List, ListItem, ListItemText } from "@mui/material";
//Img
import success_green_icon from "../img/success_green.svg";
import warning_icon from "../img/warning.svg";
import right_arrow_icon from "../img/right_arrow.svg";
import page_on_development_icon from "../img/page_on_development.svg";
// Other
import { isLogin } from "../redux/auth/auth_selectors";
import { enLang } from "../redux/global/global_selectors";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const Notifications = ({ isLoading }) => {
    const navigate = useNavigate();
    const _enLang = useSelector(enLang);
    const _isLogin = useSelector(isLogin);
    const [notifications, setNotifications] = useState([]);
    const lang = _enLang ? translations.en : translations.ukr;

    const { home } = routes;

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }
        createNotifications();
    }, [localStorage.length]);

    const createNotifications = () => {
        const notifications = [];

        for (let i = 1; i < localStorage.length - 1; i++) {
            notifications.push(JSON.parse(localStorage.getItem("notification_" + i)));
        }

        setNotifications(notifications.reverse());
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="notifications">
                    <Header text={lang.notifications} showBackArrow={false} />
                    {/* <List className="notifications_list">
            {notifications.map(({ code, status, message }, index) => (
                <ListItem key={"notification_" + index} className="notifications_list_item">
                    <Box className="notifications_list_item_helper_block">
                        <img className="notification_icon" src={code === 200 ? success_green_icon : warning_icon} alt="notification" />
                        <ListItemText className="notifications_list_item_text">{status}! </ListItemText>
                    </Box>
                    <Box className="notifications_list_item_helper_block">
                        <ListItemText className="notifications_list_item_text">
                            {message && message.slice(0, 24)}... | {code}
                        </ListItemText>
                        <img className="right_arrow_icon" src={right_arrow_icon} alt="right arrow" />
                    </Box>
                </ListItem>
            ))}
        </List> */}
                    <Box className="page_on_development_state">
                        <img className="page_on_development_state_background" src={page_on_development_icon} alt="page on development" />
                        <span className="page_on_development_state_message">{lang.page_under_development}</span>
                    </Box>

                    <Box></Box>
                </Box>
            )}
        </>
    );
};

Notifications.propTypes = {
    isLoading: PropTypes.bool,
};

export default Notifications;

// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// Components
import Title from "../components/Title";
import CustomButton from "../components/CustomButton";
import Logo from "../components/Logo";
import Header from "../components/Header";
import Loader from "../components/Loader";
// Material
import { Box } from "@mui/material";
// Other
import { routes } from "../constants/routes";
import { enLang } from "../redux/global/global_selectors";
import { isLogin } from "../redux/auth/auth_selectors";
import { translations } from "../constants/translation";

const AddObject = ({ isLoading }) => {
    const navigate = useNavigate();
    const _isLogin = useSelector(isLogin);
    const _enLang = useSelector(enLang);

    const lang = _enLang ? translations.en : translations.ukr;
    const { add_object_location, manual_adding, scan_qr } = lang;
    const { objectsManualAdding, home } = routes;

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box className="add_object">
                    <Header text={add_object_location} />
                    <Box className="add_object_logo_wrapper">
                        <Logo />
                        <Title tag="h1" text="NVISION" />
                    </Box>
                    <Box className="add_object_button_wrapper">
                        <Link to={objectsManualAdding}>
                            <CustomButton style="white" variant="text" text={manual_adding} />
                        </Link>
                        <CustomButton style="white" variant="text" text={scan_qr} disabled />
                    </Box>
                    <Box></Box>
                </Box>
            )}
        </>
    );
};

AddObject.propTypes = {
    isLoading: PropTypes.bool,
};

export default AddObject;

// React
import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// Components
import Header from "../components/Header";
import CustomButton from "../components/CustomButton";
// Material
import { Avatar, Box, Table, TableCell, TableContainer, TableBody, TableHead, TableRow, Paper, CircularProgress } from "@mui/material";
// Img
import avatar_icon from "../img/avatar.svg";
import edit_grey_icon from "../img/edit_grey.svg";
import edit_icon from "../img/edit.svg";
// Other
import { logOut } from "../redux/auth/auth_operations";
import { getStreamSlots } from "../redux/streams/streams_operations";
import { streamSlots } from "../redux/streams/streams_selectors";
import { user, isLogin } from "../redux/auth/auth_selectors";
import { enLang } from "../redux/global/global_selectors";
import { thStyle, headerStyle, tableLoaderStyle } from "../styles/components/style";
import { translations } from "../constants/translation";
import { routes } from "../constants/routes";

const Profile = ({ isLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _isLogin = useSelector(isLogin);
    const _streamSlots = useSelector(streamSlots);
    const { token, email, id } = useSelector(user);
    const _enLang = useSelector(enLang);
    const lang = _enLang ? translations.en : translations.ukr;

    const { account, logout, delete_profile, _id, demo, minimal, optimal, premium, name, available, used, tariff_plans } = lang;
    const { home, editPassword, deleteProfile /*editPhone, editMail*/ } = routes;

    const createTableData = (name, available, used) => {
        return { name, available, used };
    };

    const setTableData = useCallback(
        (hours, status) => {
            let countUsed = 0;
            let countAvailable = 0;

            _streamSlots.forEach((stream) => {
                if (stream.retention_hours === hours) {
                    stream.stream_id ? (countUsed += 1) : (countAvailable += 1);
                }
            });

            if (status === "used") return countUsed;
            if (status === "available") return countAvailable;
        },
        [_streamSlots]
    );

    const rows = [
        createTableData(demo, setTableData(24, "available"), setTableData(24, "used")),
        createTableData(minimal, setTableData(72, "available"), setTableData(72, "used")),
        createTableData(optimal, setTableData(168, "available"), setTableData(168, "used")),
        createTableData(premium, setTableData(336, "available"), setTableData(336, "used")),
    ];

    useEffect(() => {
        if (!_isLogin) {
            navigate(home);
            return;
        }

        dispatch(getStreamSlots({ token, _isLogin }));
    }, [dispatch, _isLogin]);

    const handleLogout = () => {
        dispatch(logOut({ token }));
    };

    return (
        <Box className="profile">
            <Header text={account} showBackArrow={false} showSettingsIcon />
            <Box className="profile_content">
                <Box className="profile_user_info">
                    <Box className="profile_user_info_avatar_wrapper">
                        <Avatar className="profile_avatar" src={avatar_icon} alt="avatar" />
                        <Box className="profile_user_name_wrapper">
                            <span className="profile_user_name">{email}</span>
                            <span className="profile_user_id">
                                {_id}: {id}
                            </span>
                        </Box>
                    </Box>

                    <Box className="profile_user_edit_info">
                        <Box className="profile_user_email_wrapper">
                            <p>{email}</p>
                            {/* <Link to={editMail}> */}
                            <img className="profile_user_edit_icon inactive" src={edit_grey_icon} alt="change email" />
                            {/* </Link> */}
                        </Box>
                        {/* <Box className="profile_user_phone_wrapper">
                                    <p>+380999999999</p>
                                    <Link to={editPhone}>
                                    <img className="profile_user_edit_icon" src={edit_grey_icon} alt="edit" />
                                    </Link>
                                    </Box> */}
                        <Box className="profile_user_password_wrapper">
                            <p>••••••••</p>
                            <Link to={editPassword}>
                                <img className="profile_user_edit_icon active" src={edit_icon} alt="change password" />
                            </Link>
                        </Box>
                    </Box>
                </Box>

                {isLoading ? (
                    <CircularProgress sx={tableLoaderStyle} color="info" />
                ) : (
                    <Box className="profile_content_table">
                        <span className="span">{tariff_plans}</span>
                        <br />
                        <br />
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={headerStyle}>{name}</TableCell>
                                        <TableCell sx={headerStyle} align="center">
                                            {available}
                                        </TableCell>
                                        <TableCell sx={headerStyle} align="center">
                                            {used}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map(({ name, available, used }) => (
                                        <TableRow key={name}>
                                            <TableCell sx={thStyle} component="th" scope="row">
                                                {name}
                                            </TableCell>
                                            <TableCell sx={thStyle} align="center">
                                                {available}
                                            </TableCell>
                                            <TableCell sx={thStyle} align="center">
                                                {used}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}

                <Box className="profile_content_button_wrapper">
                    <CustomButton type="button" style="dark" variant="text" text={logout} onClick={handleLogout} />
                    <Link to={deleteProfile}>
                        <CustomButton style="transparent" variant="text" text={delete_profile} />
                    </Link>
                </Box>
            </Box>
            <Box></Box>
        </Box>
    );
};

Profile.propTypes = {
    isLoading: PropTypes.bool,
};

export default Profile;

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

// get app version
export const getAppVersion = createAsyncThunk("global/getAppVersion", async () => {
    try {
        const { data, status } = await axios.get(location.origin + "/global_settings.json");
        return { data, status };
    } catch (e) {
        console.log({ message: e.code, status: e.response.status });
        return { message: e.code, status: e.response.status };
    }
});

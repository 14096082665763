// React
import React from "react";
import { useSelector } from "react-redux";
// Other
import { enLang } from "../redux/global/global_selectors";
import { translations } from "../constants/translation";

const OfflineLayout = () => {
    const _enLang = useSelector(enLang);
    const lang = _enLang ? translations.en : translations.ukr;
    const { no_internet_connection } = lang;

    return <div className="offline_layout">{no_internet_connection}</div>;
};

export default OfflineLayout;

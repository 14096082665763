import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import VanillaToasts from "../../components/toasts/vanillatoasts";
import { translations } from "../../constants/translation";
import { /* setSuccessNotification */ setWarningNotification, setErrorNotification, getLanguage } from "../local_storage";

// get object
export const getObjectsList = createAsyncThunk("objects/getObjectsList", async ({ token, _isLogin, _showNotifications }) => {
    const { error500, objects_has_been_loaded, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.get("/api/v1/groups", { headers: { "X-Auth-Key": token } });

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: objects_has_been_loaded,
                    type: "success",
                    timeout: 5000,
                });
            }

            if (_isLogin) {
                // setSuccessNotification(status, objects_has_been_loaded);
            }
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });

            if (_isLogin) {
                setWarningNotification(status);
            }
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        if (e.response.data !== "invalid token") {
            VanillaToasts.create({
                title: error,
                text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
                type: "error",
                timeout: 5000,
            });
        }

        if (_isLogin) {
            setErrorNotification(e);
        }

        return { status: e.response.status };
    }
});

// create object
export const createObject = createAsyncThunk("objects/createObject", async ({ token, _isLogin, name, _showNotifications }) => {
    const { error500, object_has_been_created, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.post("/api/v1/groups", { name }, { headers: { "X-Auth-Key": token } });

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: object_has_been_created,
                    type: "success",
                    timeout: 5000,
                });
            }

            if (_isLogin) {
                // setSuccessNotification(status, object_has_been_created);
            }
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });

            if (_isLogin) {
                setWarningNotification(status);
            }
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });

        if (_isLogin) {
            setErrorNotification(e);
        }

        return { status: e.response.status };
    }
});

// edit object
export const editObject = createAsyncThunk("objects/editObject", async ({ token, _isLogin, name, id, _showNotifications }) => {
    const { error500, object_has_been_edited, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.patch("/api/v1/groups/" + id, { name }, { headers: { "X-Auth-Key": token } });

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: object_has_been_edited,
                    type: "success",
                    timeout: 5000,
                });
            }

            if (_isLogin) {
                // setSuccessNotification(status, object_has_been_edited);
            }
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });

            if (_isLogin) {
                setWarningNotification(status);
            }
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });

        if (_isLogin) {
            setErrorNotification(e);
        }

        return { status: e.response.status };
    }
});

// delete object
export const deleteObject = createAsyncThunk("objects/deleteObject", async ({ id, token, _isLogin, _showNotifications }) => {
    const { error500, object_has_been_deleted, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.delete("/api/v1/groups/" + id, { headers: { "X-Auth-Key": token } });

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: object_has_been_deleted,
                    type: "success",
                    timeout: 5000,
                });
            }

            if (_isLogin) {
                // setSuccessNotification(status, object_has_been_deleted);
            }
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });

            if (_isLogin) {
                setWarningNotification(status);
            }
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });

        if (_isLogin) {
            setErrorNotification(e);
        }

        return { status: e.response.status };
    }
});

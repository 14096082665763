import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import VanillaToasts from "../../components/toasts/vanillatoasts";
import { translations } from "../../constants/translation";
import { /* setSuccessNotification */ setWarningNotification, setErrorNotification, getLanguage } from "../local_storage";

// get user IP
export const getUserIP = createAsyncThunk("service/getUserIP", async ({ token, _isLogin, _showNotifications }) => {
    const { error500, ip_has_been_obtained, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    try {
        const { data, status } = await axios.get("/api/v1/service/ip", { headers: { "X-Auth-Key": token } });

        if (status === 200) {
            if (_showNotifications) {
                VanillaToasts.create({
                    title: success,
                    text: ip_has_been_obtained,
                    type: "success",
                    timeout: 5000,
                });
            }

            if (_isLogin) {
                // setSuccessNotification(status, ip_has_been_obtained);
            }
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });

            if (_isLogin) {
                setWarningNotification(status);
            }
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });

        if (_isLogin) {
            setErrorNotification(e);
        }

        return { status: e.response.status };
    }
});

// test port
export const testPort = createAsyncThunk("service/testPort", async ({ addr, port, token, _isLogin }) => {
    const { error500, port_is_open, success, warning, error, something_went_wrong } =
        getLanguage() === "en" ? translations.en : translations.ukr;
    const params = { addr, port };
    try {
        const { data, status } = await axios.post("/api/v1/service/check_port", params, { headers: { "X-Auth-Key": token } });

        if (status === 200) {
            VanillaToasts.create({
                title: success,
                text: port_is_open,
                type: "success",
                timeout: 5000,
            });

            if (_isLogin) {
                // setSuccessNotification(status, port_is_open);
            }
        } else {
            VanillaToasts.create({
                title: warning,
                text: something_went_wrong + "Code: " + status,
                type: "warning",
                timeout: 5000,
            });

            if (_isLogin) {
                setWarningNotification(status);
            }
        }
        return { data, status };
    } catch (e) {
        console.log({ message: e.response.data, status: e.response.status });
        VanillaToasts.create({
            title: error,
            text: e.response.status === 500 ? error500 : e.response.data + ". Code: " + e.response.status,
            type: "error",
            timeout: 5000,
        });

        if (_isLogin) {
            setErrorNotification(e);
        }

        return { status: e.response.status };
    }
});
